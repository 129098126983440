import React, { Component } from 'react';
import card1 from '../../imagens/piscina emanatória.jpg';
import card2 from '../../imagens/SPA.jpg';
import card3 from '../../imagens/alimentação.jpg';
import boliche from '../../imagens/boliche.jpg';
import checkout from '../../imagens/checkout.jpg';
import './Beneficios.css';
import '../Styles/FontsUsadas.css'

class Beneficios extends Component {
    render() {
        const fonteTitulo = {
            fontFamily: "CoHeadlineBold"
        }
        return (
            <div id="beneficios">
                <div className="tituloBeneficios ">
                    <p className="fonteTitulo">Benefícios</p>
                </div>
                <div className="container-fluid relative">
                    <div className="row no-gutters">
                        <div className="offset-xl-1 col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="card cWidth">

                                <img className="card-img-top" src={card3} alt="Imagem de capa do card" />
                                <div className="overlay bRadius " id="overlay1">
                                    <div className="texto">
                                    Desconto de 30% no valor da refeição extra sobre o preço de tabela. *Consulte valores                        
                                    </div>
                                </div>
                                <div className="cards-body text-center" id="card1">
                                    <h5 className="card-title mt8">30% OFF na alimentação</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="card cWidth">
                                <img className="img-fluid card-img-top" src={card2} alt="Imagem de capa do card" />
                                <div className="overlay bRadius" id="overlay2">
                                    <div className="texto">
                                        Desconto de 15% nos serviços das Termas e SPA, caso haja promoção o desconto será acumulativo.
                                    </div>
                                </div>
                                <div className="cards-body text-center" id="card2">
                                    <h5 className="card-title mt8">15% OFF no SPA</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="card cWidth">
                                <img className="card-img-top " src={card1} alt="Imagem de capa do card" />
                                <div className="overlay bRadius" id="overlay3">
                                    <div className="texto">Acesso gratuito à Piscina Emanatória no Grande Hotel Termas de Araxá. 

                        </div>
                                </div>
                                <div className="cards-body" id="card3">
                                    <h5 className="card-title mt8">Acesso exclusivo à Piscina Emanatória</h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="card cWidth">
                                <img className="card-img-top" src={boliche} alt="Imagem de capa do card" />
                                <div className="overlay bRadius" id="overlay4">
                                    <div className="texto">Desconto de 15% no Boliche, válidos para o Tauá Resort Caeté e Tauá Resort Atibaia. Caso haja promoção o desconto será acumulativo.
                        </div>
                                </div>
                                <div className="cards-body text-center " id="card4">
                                    <h5 className="card-title mt8">15% OFF no boliche </h5>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="card cWidth">
                                <img className="card-img-top" src={checkout} alt="Imagem de capa do card" />
                                <div className="overlay bRadius" id="overlay5">
                                    <div className="texto">Check-out estendido até as 18 horas conforme disponibilidade do hotel.

                        </div>
                                </div>
                                <div className="cards-body" id="card5">
                                    <h5 className="card-title mt8">Check-out estendido</h5>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

        );
    }
}
export default Beneficios;