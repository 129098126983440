import axios from 'axios';


//export const api = axios.create({ baseURL: 'https://dfaodfif.tauavipclub.com.br/'});
// export const api = axios.create({ baseURL: 'http://192.168.0.117:5060'});


export const api = axios.create({ baseURL: 'https://dfaodfif.tauavipclub.com.br/' });


//export const api = axios.create({ baseURL: 'http://vps3856.publiccloud.com.br'});
//export const api = axios.create({ baseURL: 'http://54.233.173.5:5060' });
//export const api = axios.create({ baseURL: 'https://18.229.222.98:5060' });

