import React, { Component } from 'react';
import './FooterSite.css';
import logo2 from '../../imagens/logobra.png';
import logotaua from '../../imagens/taua-logo.png';

 class FooterSite extends Component{
     render(){
         return(  
            <div>    
                 
            <footer className="page-footer font-small mdb-color pt-4 bgSmoke">
                <div className=" text-center text-md-left">
                    <div className="row text-center text-md-left mt-3 pb-3">
                   
                        <div className="logo col-md-3 col-lg-3 col-xl-3 mx-auto mt-3">
                            <img src={logo2} className="logo-footer d-block image-fluid" /> 
                            <a href="https://tauaresorts.com.br/" target="_blank"><img src={logotaua} className="logo-taua-footer d-block img-fluid" alt="Responsive image" ></img></a>
                        </div>
                        
                        
                        <div className="intro col-md-3 col-lg-4 col-xl-4 mx-auto mt-3">               
                            <p>
                                Commm maisss de 30 anos de mercado, a Rede Tauá de hotéis possui atualmente 3 grandes resorts em Minas Gerais e São Paulo, com programação voltada para a família e estrutura completa para eventos.
                            </p>
                            <p>
                                Acesse nosso site e conheça mais.
                            </p>  
                            <p>
                            <a className="linkTaua" href="https://tauaresorts.com.br/" target="_blank">www.tauaresorts.com.br</a>
                            </p>                       
                        </div>

                        

                        <div className="end col-md-4 col-lg-4 col-xl-4 mx-auto mt-3">
                            <p>
                                <i className="fa fa-home mr-3"></i>Alameda Oscar Niemeyer, 500, 3º andar, 
                                Vila da Serra, Nova Lima / MG</p>
                            <p>
                                <i className="fa fa-phone"></i> 31 3236 1920</p>                           
                            <p>
                                <i className="fa fa-whatsapp"></i> 31 99954 9936</p>                           
                            <p>
                                <i className="fa fa-hourglass-start"></i> Segunda a sexta 08h às 18h e sábado 08h às 12h</p>
                            <p>
                                <i className="fa fa-envelope mr-3"></i>relacionamentotvc@taua.com.br  
                            </p>
                            <p>
                                <i className="fa fa-envelope mr-3"></i>reservastvc@taua.com.br
                            </p>
                        </div>
                    </div>
                    <hr className="barrinha"></hr>

                    <div className="row d-flex ">
                        <div className="col-md-7 col-lg-8">
                            <p className="text-md-left">
                                <a href="http://smarti9.com.br/" target="_blank">© 2019 Smarti9 made with love</a>
                            </p>
                        </div>
                        <div className="col-md-5 col-lg-4 ml-lg-0">
                            <div className="text-md-right">
                                <p>Politiva de privacidade</p>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            </div>
         );
     }
 }
 export default FooterSite;
