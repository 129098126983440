import React ,{Component} from 'react'
import {Button,Row,Col,Container} from 'reactstrap'
import '../Styles/AboutUs.css'
import pool from '../../imagens/poolCirculo.png'
import AOS from 'aos';
import 'aos/dist/aos.css'
import { Link } from 'react-scroll'

AOS.init();
export default class AboutUs extends Component{
    render()
    {
        return(
            <div id="aboutUs" className="container-fluid static ovHidden px-0  marginTopz oBlock">
                  <div className="row no-gutters">
                      <div className="offset-xl-1 col-12 col-lg-12 col-md-12 col-xl-5 text-center col-sm-12 no-gutters">
                          <div className=" divTextoAbout ">
                                <h1 className="TituloAbout">
                                    O QUE É O TVC 
                                </h1>
                                <p className="textoParagrafo">
                                O Tauá Vip Club é um clube de vantagens, no qual utilizamos o sistema de tempo compartilhado: você adquire o direito de se hospedar em um dos mais de 4.500 hotéis ao redor do mundo e em mais de 100 destinos nacionais incluindo a rede Tauá de hotéis e ainda usufrui de toda a diversão proporcionada pelo Tauá Aquapark Indoor, o único parque aquático indoor da América Latina. 
                                </p>
                                <Link
                                    activeClass="active"
                                    to="contatos"
                                    spy={true}
                                    smooth={true}
                                    offset={-70}
                                    duration={500}

                                    >
                                    <li className="botaoAbout">
                                        <a  className="myButton">Contate-nos</a>
                                    </li>
                                </Link>
                                
                                
                          </div>
                      </div>
                        <div className=" col-lg-10 col-12 col-xl-6 col-sm-10 col-md-8">
                            <div className="divCirculo posRelative"
                                data-aos="slide-left"
                                data-aos-offset="300"
                                data-aos-easing="ease-in-sine">
                            </div>
                        </div>
                      </div>    
                  </div>
            




        )
        
    }
}