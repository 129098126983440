
import React,{Component} from 'react'
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import img1 from '../../imagens/1.jpg'
import img2 from '../../imagens/2.jpg'
import img3 from '../../imagens/3.jpg'
import img4 from '../../imagens/img5.jpg'
import tc from "../../imagens/tc.jpg"

import '../Styles/CarouselNovo.css'
export default class CarouselNovo extends Component {
    render() {
        return (
        <div className="DivSize">
                <Carousel className="carouselRCI">
                    <div className="roundedBorders">
                        <img className="img-fluid obCover" id="imgRci" src={img1} />
                    </div>
                    <div className="roundedBorders">
                        <img className="img-fluid obCover" id="imgRci" src={img2}/>
                    </div>
                    <div className="roundedBorders">
                        <img  className=" obCover"  id="imgRci" src={img4} />
                    
                    </div>
            </Carousel>
        </div>

        );
    }
}
 
