import React, { Component } from "react";
import { Link } from 'react-router-dom';

export default class logi extends Component {

  constructor(props){
    super(props);
     
      this.state = {
        login : "",
        senha : ""
      }
      console.log("senha",this.senha)
    }
    
    handleChange = event =>{
   
      console.log('event', event.target)
  
      switch (event.target.id) {
        case 'nome_login': 
        console.log(event.target.value)
        this.setState(
          {
              login : event.target.value
          }
        )
        break;
        case 'email_login': 
        console.log(event.target.value)
        this.setState(
          {
            senha : event.target.value,       
          }
        )
        console.log("teste3",this.state.senha)
        break;
      default:
        break;      
    }
  }

    validar = event =>{
     
      if( this.state.senha === "3211" && this.state.login === "admin"){
        this.props.history.push("/admin/contrato");
      }
      else {
        alert("Login ou senha incorretos")
      }
    }
    
      
 
    

  render() {
    return (
        <div className="container" >
          
            <div className="content">      
              
              <div id="login">
                <form> 
                  <h1>Login</h1> 
                  <p> 
                    <label for="nome_login">Login:</label>
                    <input id="nome_login" name="nome_login" required="required" type="text" onChange={this.handleChange}/>
                  </p>
                  
                  <p> 
                    <label for="email_login">Senha:</label>
                    <input id="email_login" name="email_login" required="required" type="password" onChange={this.handleChange} /> 
                  </p>             
                  
                  <p> 
                  <button onClick={this.validar} style={{overflow:'hidden', 
                    borderRadius:4, backgroundColor: '#5b267b'}}
                    style={{color: 'white', backgroundColor: '#5b267b'}}  className="btn "> Logar </button>
                  </p>
                  
                  
                </form>
              </div>
            </div>
          </div>
              
    )};
}


