import React, { Component } from "react";

import {api} from '../../services/api';
import Divider from '@material-ui/core/Divider';
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";

import {cpfMask} from '../mask/index'

import { connect } from 'react-redux';

import {hist} from '../../index';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import TimerIcon from '@material-ui/icons/Timer';


//import ClipLoader from "react-spinners/ClipLoader";
const override = css`
  display: block;
  align: center;
  border-color: orange;
  position: absolute;
  z-index: 99;
  top:50%;
  left:40%;
`;

const Snack = {
  message: '',
  color: '',
  icon: null
}

var timer_obj;

class contrato extends Component {

  constructor(props){
    super(props)
      this.state = {
      contratos:[], 
      // totalContratado:'',
      // totalPago:'',
      // percIntegr:'',
      // valorPonto:'',
      // saldoAtual:'',
      // saldoPontos:'',
      // parcelasAtraso:'',
      // id:'',
      // hotelRef:'',
      // dataAss:'',
      // vigencia:'',
      //profiles:[],
      cpf:'',
      loading: false,
      expanded: "0",
    }

  }

  Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  loadingSnackBar = (snack) =>  { //Função para carregar a snack bar 
  this.setState({
      snackBarColor: snack.color,
      snackBarMessage: snack.message,
      snackbarIcon: snack.icon,
      snackBarOpen: true
  });
  setTimeout(
      function () {
          this.setState({ snackBarOpen: false });
      }.bind(this),
      5500
  );
  }

  expandedControl = panel => (event, isExpanded) => {
    var t = (isExpanded ? panel : false);
    this.setState({expanded: t})
  };

  componentWillUnmount()
  {
    if(this.state.loading == true)
    {
      this.setState({loading:false})
    }
  }

  componentDidMount()
  {
    if((this.props.user === null) || (typeof(this.props.user) === 'undefined'))
    {
      hist.push("/home")
      // voltar para a tela inicial
    }
    else
    {
      this.setState({cpf: this.props.user.cpf})
  
      setTimeout(() => {
        this.getContrato()
      },100)
    }
  }

  getContrato = event =>  {
    this.setState({loading:true})

    timer_obj = setTimeout(() => {
      if(this.state.loading == true)
      {
        Snack.message ="Falha na comunicação com o servidor"
        Snack.color = "warning"
        Snack.icon = ErrorIcon
        this.loadingSnackBar(Snack)
        this.setState({loading:false})
      }
    }, 20000)

    api.get('/consultaContrato?cpf='+this.state.cpf.replace('.','').replace('.','').replace('-',''), { headers: { token: this.props.user.access_token, email: this.props.user.email}})
      .then(response => {
        this.setState({ 
          contratos: [].concat(response.data.Contrato),
        })
        this.setState({loading:false})
        if(this.state.parcelasAtraso > 0){
          Snack.message ="Você tem parcelas em atraso, entre em contato com o e-mail relacionamentotvc1@taua.com.br ou pelo telefone (31) 3236 1920"
          Snack.color = "warning"
          Snack.icon = ErrorIcon
          this.loadingSnackBar(Snack)
        }
      })
      .catch(error => 
        {
          console.log("ERROR:",error)
          Snack.message ={error}.error.response.data.message
          Snack.color = "warning"
          Snack.icon = ErrorIcon
          this.loadingSnackBar(Snack)
          // alert(error.response.data.message);
          this.setState({loading:false})
          clearTimeout(timer_obj);
        })
  }

  render() {
    const { cpf } = this.state
    return (
      <div>   
        <div className="sweet-loading">
        <ClipLoader
          css={override}
          size={150}
          //size={"150px"} this also works
          color={"#123abc"}
          loading={this.state.loading}
        />
        </div> 
        <Snackbar
          place="bc"
          open={this.state.snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
          >
          <MuiAlert elevation={6} variant="filled" severity={this.state.snackBarColor}>
            {this.state.snackBarMessage}
          </MuiAlert>
        </Snackbar> 
        {
          this.state.contratos.map(
            (contrato, i) =>(               
              <div key={contrato.ID}>
                <ExpansionPanel id={i.toString()} expanded={this.state.expanded === i.toString()} onChange={
                      this.expandedControl(i.toString())
                    }>
                  <ExpansionPanelSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Typography>Contrato {contrato.ID}</Typography>
                  </ExpansionPanelSummary>
                  <ExpansionPanelDetails>
                    <Typography>
                      <form>          
                      <label>*Para alterações, entrar em contato com a central do TVC pelo telefone (31) 3236 1920</label>
                      <h3>Informações do Contrato:</h3>
                        <div className="form-row"> 
                          <div className="form-group col-md-6">
                            <label htmlFor="inputID">Identificação do Contrato</label>
                            <input readOnly type="text" className="form-control" id="inputId" value={contrato.ID} />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="inputHotel">Hotel onde foi assinado</label>
                            <input readOnly type="text" className="form-control" id="inputHotel" value={contrato.HotelNome} />
                          </div>
                        </div>
                        <div className="form-row">
                          <div className="form-group col-md-6">
                            <label htmlFor="inputDataAss">Data da assinatura</label>
                            <input readOnly type="name" className="form-control" id="inputEmail4" value={contrato.Assinatura} />
                          </div>
                          <div className="form-group col-md-6">
                            <label htmlFor="inputVigencia">Término da vigência</label>
                            <input readOnly type="name" className="form-control" id="inputVigencia"  value={contrato.Vigencia}/>
                          </div>
                        </div>
                        <br></br>
                        <Divider variant="middle" />
                        <br></br>
                        <h3>Saldo do contrato:</h3>
                        
                        <div className="form-row">
                          <div className="form-group col-md-4">
                            <label htmlFor="inputTotal">Total contratado</label>
                            <input readOnly type="text" className="form-control" id="inputTotal" value={contrato.Valores.TotalContrato}/>
                          </div>
                          <div className="form-group col-md-4">
                            <label htmlFor="inputValorPonto">Valor do ponto</label>
                            <input readOnly type="text" className="form-control" id="inputValorPonto" value={contrato.Valores.ValorPonto}/>
                          </div>
                          <div className="form-group col-md-4">
                          <label htmlFor="inputParcelaAtraso">Quantidade de parcelas em atraso</label>
                          <input readOnly type="text" className="form-control" id="inputParcelas" value={contrato.QtdeParcAtraso} />
                          <label >*Caso tenha parcelas em atraso, entrar em contato com o e-mail relacionamentotvc1@taua.com.br ou pelo telefone (31) 3236 1920</label>
                        </div>
                        </div>
                        <div className="form-row">  
                        </div>  
                        </form>
                        <br></br>
                        <Divider variant="middle" />
                        <br></br>
                        <h3 style={{}}>Perfis do contrato:</h3>
                        <form style={{padding:10}} >
                          {
                            
                            [].concat(contrato.Profiles.Profile).map(
                              profile =>(
                            
                                  <div>
                                  <div className="form-row">
                                    <div className="form-group col-md-5">
                                      <label htmlFor="inputNome">Nome</label>
                                      <input readOnly type="text" className="form-control" id="inputNome" value={profile.Customer.PersonName.GivenName}/>
                                    </div>
                                    <div className="form-group col-md-5">
                                      <label htmlFor="inputSobrenome">Sobrenome</label>
                                      <input readOnly type="text" className="form-control" id="inputSobrenome" value={profile.Customer.PersonName.Surname }/>
                                    </div>      
                                  </div>

                                  <h5>Telefone:</h5>
                                  <div className="form" style={{padding:10}}>
                                  { 
                                    typeof(profile.Customer.Telephone) !== 'undefined' ? 
                                      [].concat(profile.Customer.Telephone).map(
                                        tel =>(
                                          <div className="row">
                                            <div className="form-group col-md-3">
                                              <label htmlFor="inputDDD">DDD</label>
                                              <input readOnly type="text" className="form-control" id="inputDDD" value={tel.AreaCityCode }/>
                                            </div>
                                            <div className="form-group col-md-6">
                                              <label htmlFor="inputNumero">Número</label>
                                              <input readOnly type="text" className="form-control" id="inputDDD" value={tel.PhoneNumber}/>
                                            </div>
                                          </div>
                                        )
                                      )
                                    : (<div></div>)
                                  }
                                  </div>

                                  <div className="form-group ">
                                    <label htmlFor="inputEmail">Email</label>
                                    <input readOnly type="text" className="form-control" id="inputEmail" value={profile.Customer.Email} />
                                  </div>
                                  <h5>Endereço:</h5>
                                  <div className="form-row" style={{padding:10}}>
                                    <div className="form-group col-md-9">
                                      <label htmlFor="inputRuz">Rua</label>
                                      <input readOnly type="text" className="form-control" id="inputRua" value={profile.Customer.Address.AddressLine}/>
                                    </div>
                                    <div className="form-group col-md-3">
                                      <label htmlFor="inputNumero">Número</label>
                                      <input readOnly type="text" className="form-control" id="inputNumero" value={profile.Customer.Address.StreetNmbr}/>
                                    </div>
                                  </div>
                                  <div className="form-row" style={{padding:10}}>
                                    <div className="form-group col-md-6">
                                      <label htmlFor="inputBairro">Bairro</label>
                                      <input readOnly type="text" className="form-control" id="inputBairro" value={profile.Customer.Address.District}/>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label htmlFor="inputCidade">Cidade</label>
                                      <input readOnly type="text" className="form-control" id="inputCidade" value={profile.Customer.Address.CityName}/>
                                    </div>
                                  </div>
                                  <div className="form-row" style={{padding:10}}>
                                    <div className="form-group col-md-3">
                                      <label htmlFor="inputTotal">Estado</label>
                                      <input readOnly type="text" className="form-control" id="inputEstado" value={profile.Customer.Address.StateProv}/>
                                    </div>
                                    <div className="form-group col-md-5">
                                      <label htmlFor="inputBairro">Pais</label>
                                      <input readOnly type="text" className="form-control" id="inputBairro" value={profile.Customer.Address.CountryName}/>
                                    </div>
                                  </div>
                                  <h5>Documento:</h5>
                                  <div className="form-row" style={{padding:10}}>
                                    <div className="form-group col-md-2">
                                      <label htmlFor="inputTipo">Tipo</label>
                                      <input readOnly type="text" className="form-control" id="inputTipo" value={profile.Customer.Document.DocType} />
                                    </div>
                                    <div className="form-group col-md-4">
                                      <label htmlFor="inputId">Número de identificação</label>
                                      <input readOnly type="text" className="form-control" id="inputId" value={profile.Customer.Document.DocID} />
                                    </div>
                                    <div className="form-group col-md-4">
                                      <label htmlFor="inputNascimento">Data de nascimento</label>
                                      <input readOnly type="text" className="form-control" id="inputNascimento"value={profile.Customer.Document.BirthDate} />
                                    </div>

                                    <div className="form-group col-md-2">
                                      <label htmlFor="inputGenero">Gênero</label>
                                      <input readOnly type="text" className="form-control" id="inputGenero"value={profile.Customer.Document.Gender} />
                                    </div>
                                  </div>
                                  <br></br>
                                  <Divider variant="middle" />
                                  <br></br>
                                  </div>
                                )
                              )
                            }
                                </form>
                              
                    </Typography>
                  </ExpansionPanelDetails>
                </ExpansionPanel>
            </div>
            )
          )
        }
        
                </div>
    )
  }
}

const mapStateToProps = ({ login }) => {
  const { user } = login;

  return {
    user
  };
};

export default connect (mapStateToProps, {
  
}) (contrato);
