import React, { Component } from 'react';
import './Navbar.css';
import {api} from '../../services/api';
import logo2 from '../../imagens/logobra.png';
import logoLogin from '../../imagens/lop.png';
import logo_fundo_branco from '../../imagens/logonova.png';
import { Link } from 'react-scroll';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
  useLocation,
  Link as Link2
} from "react-router-dom";
import Dialog from '@material-ui/core/Dialog';
import DialogAction from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Divider from '@material-ui/core/Divider';

import { connect } from 'react-redux';

import {cpfMask} from '../mask/index'

import {
  onLoginPress
} from '../../actions';
import { Alert } from 'reactstrap';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import TimerIcon from '@material-ui/icons/Timer';



var timer_obj;

const Snack = {
  message: '',
  color: '',
  icon: null
}

class Navbar extends Component {

  constructor(props){
    super(props);
    this.state = {
      access_token : null,
      email : "",
      cpf : null,
      open: false,
      password: "",
      loading: false,
      cpf_temp: "",
      situacao: 0,
      nome: "",
      senha_temp: "",
      sobrenome: ""
    }
  }
  Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

loadingSnackBar = (snack) =>  { //Função para carregar a snack bar
  this.setState({
      snackBarColor: snack.color,
      snackBarMessage: snack.message,
      snackbarIcon: snack.icon,
      snackBarOpen: true
  });
  setTimeout(
      function () {
          this.setState({ snackBarOpen: false });
      }.bind(this),
      5500
  );
}
    handleChange = event =>{
      switch (event.target.id) {
        case 'email_login': 
        this.setState(
          {
            email : event.target.value
          }
        )
        break;
        case 'nome_login': 
        this.setState(
          {
            nome : event.target.value
          }
        )
        break;
        case 'sobrenome_login': 
        this.setState(
          {
            sobrenome : event.target.value
          }
        )
        break;
        case 'senha_login': 
        this.setState(
          {
            password : event.target.value,       
          }
        )
        break;
        case 'senha_temp': 
        this.setState(
          {
            senha_temp : event.target.value,       
          }
        )
        break;
        case 'cpf_temp':
          this.setState(
            {
              cpf_temp: event.target.value,
            }
          )
      default:
        break;      
    }
  }

  button_action = event =>
  {
    switch (event.target.id) 
    {
      case 'esqueceu':
        {          
          this.setState({
            cpf : "",
            password: "",
            cpf_temp: "",
            nome: "",
            senha_temp: "",
            sobrenome: "",
            situacao: 2
          })
        }
      break;
      case 'nova':
        {
          this.setState({
            email : "",
            cpf : "",
            password: "",
            cpf_temp: "",
            nome: "",
            senha_temp: "",
            sobrenome: "",
            situacao: 3
          })
        }
      break;
      default:
        this.setState({
          email : "",
          cpf : "",
          password: "",
          cpf_temp: "",
          nome: "",
          senha_temp: "",
          sobrenome: "",
          situacao: 0
        })
    }
  }


    actionLogar = event =>{
      this.setState({
        loading:true,
        email : "",
        cpf : "",
        password: "",
        cpf_temp: "",
        nome: "",
        senha_temp: "",
        sobrenome: ""
      })
      timer_obj = setTimeout(() => {
        if(this.state.loading == true)
        {
          this.setState({loading:false})
          //alert("Falha na comunicação com o servidor")
          this.setState({loading:false})
          Snack.message ="Falha na comunicação com o servidor"
          Snack.color = "warning"
          Snack.icon = ErrorIcon
          this.loadingSnackBar(Snack)
        }
      }, 10000)

      switch(this.state.situacao)
      {
        case 0:
          {
            const logar = {
              username: this.state.email,
              password: this.state.password
            }
    
            api.post('/loginHospede',logar)
            .then(response => {
              if(response.data.cpf === "")
              {
                this.setState({loading:false})
                Snack.message ="Por favor, insira o seu cpf para continuar"
                Snack.color = "warning"
                Snack.icon = ErrorIcon
                this.loadingSnackBar(Snack)
                this.setState({
                  loading:false,
                  access_token: response.data.access_token,
                  email: response.data.email,
                  situacao:1
                })
              }
              else
              {
                this.props.onLoginPress(response.data);
    
                setTimeout(() => {
                  this.setState({
                    loading:false,
                    access_token: this.props.user.access_token,

                    email: response.data.email,
                    cpf: this.props.user.cpf,
                    situacao: 4  
                  })
                },100)
              }
              clearTimeout(timer_obj);
            })
            .catch(error => {  
              console.log("ERRO:",error)
              //alert("Usuário ou senha incorretos");
              this.setState({loading:false})
              Snack.message ="Usuário ou senha incorretos";
              Snack.color = "warning"
              Snack.icon = ErrorIcon
              this.loadingSnackBar(Snack)
              this.setState({loading:false})
              clearTimeout(timer_obj);
            })
          }
        break;
        case 1:
          {
            const logar = {
              cpf: this.state.cpf_temp.replace('.','').replace('.','').replace('-',''),
            }
            api.put('/hospede/cpf',logar,{ headers: { token: this.state.access_token, email: this.state.email}})
            .then(response => {
              this.setState({
                loading:false,   
              })
              this.setState({
                situacao:0
              })
              clearTimeout(timer_obj);

              this.actionLogar()
            })
            .catch(error => {  
              console.log("ERRO:",error)
              this.setState({loading:false})
              clearTimeout(timer_obj);
            })
          }
        break;
        case 2: // Esqueceu a senha
          {
            const logar = {
              email: this.state.email,
            }
            api.post('/loginHospede/forgot-password',logar)
            .then(response => {
              this.setState({
                loading:false,   
              })
              //alert("Redefinição de senha enviada para o email ",this.state.email)
              this.setState({loading:false})
              Snack.message =("Redefinição de senha enviada para o email " + this.state.email)
              Snack.color = "success"
              Snack.icon = ErrorIcon
              this.loadingSnackBar(Snack)
              this.setState({
                cpf : "",
                password: "",
                cpf_temp: "",
                nome: "",
                senha_temp: "",
                sobrenome: "",
                situacao:0
              })
              clearTimeout(timer_obj);
            })
            .catch(error => {  
              console.log("ERRO:",error)
              this.setState({loading:false})
              clearTimeout(timer_obj);
            })
          }
        break;
        case 3: // Cadastro de novo hospede
          {
            const logar = {
              email: this.state.email,
              cpf: this.state.cpf_temp.replace('.','').replace('.','').replace('-',''),
              nome: this.state.nome,
              sobrenome: this.state.sobrenome,
              senha: this.state.password,
            }
            if(this.state.password !== this.state.senha_temp)
            {
              //alert("As senha não são iguais. Confira e tente novamente")
              this.setState({loading:false})
              Snack.message ="As senhas não são iguais. Confira e tente novamente"
              Snack.color = "warning"
              Snack.icon = ErrorIcon
              this.loadingSnackBar(Snack)
              clearTimeout(timer_obj);
            }
            else
            {
               this.setState({
                  email : "",
                  cpf : "",
                  password: "",
                  cpf_temp: "",
                  nome: "",
                  senha_temp: "",
                  sobrenome: "",
                  situacao:0
                })
              api.post('/loginHospede/cadastro',logar)
              .then(response => {
                this.setState({
                  loading:false,   
                })
                this.setState({
                  email : "",
                  cpf : "",
                  password: "",
                  cpf_temp: "",
                  nome: "",
                  senha_temp: "",
                  sobrenome: "",
                  situacao:0
                })
                clearTimeout(timer_obj);
                //alert(response.data.message)
                
                this.setState({loading:false})
                Snack.message =response.data.message
                Snack.color = "warning"
                Snack.icon = CheckIcon
                this.loadingSnackBar(Snack)
              })
              .catch(error => {  
                console.log("ERRO:",error)
                Snack.message = ({error}.error.response.data.message);
                Snack.color = "warning"
                Snack.icon = ErrorIcon
                this.loadingSnackBar(Snack)
                this.setState({loading:false})
                this.setState({loading:false})
                clearTimeout(timer_obj);
              })
            }
          }
        break;
        case 4:
          {
            // Fazer nada
          }
        break; 
        default:
          {
            this.setState({
              email : "",
              cpf : "",
              password: "",
              cpf_temp: "",
              nome: "",
              senha_temp: "",
              sobrenome: "",
              situacao:0
            })
          }
      }
    }

    NextPage = () => {
      switch(this.state.situacao)
      {
        case 0: // caso inicial de usuario e senha simples
          {
            return(
              <div className="form col" style={{width:'100%', justifyContent: 'space-between',}}>
                <h4 className="login_style">E-mail:</h4>
                <input id="email_login" className="form-control" type="text" onChange={this.handleChange}/>
                
                <h4 className="login_style">Senha:</h4>
                <input id="senha_login" className="form-control" type="password" onChange={this.handleChange} onKeyDown={(value)=>{
                  if(value.key === 'Enter')
                  {
                    this.actionLogar()
                  }
                }}/> 
                <br></br>
                <button  className="botaosenha " id="esqueceu" onClick={this.button_action}>Esqueceu sua senha?</button>
                <br></br>
                <button  className="botaosenha " id="nova" onClick={this.button_action}>Cadastre-se</button>
                        
              </div>)
          }
        break;
        case 1: // situação do cadastro faltando o cpf
          {
            return(
              <div className="content">
                <h4 className="login_style">E-mail:</h4>
                <input id="email_login" className="form-control" type="text" onChange={this.handleChange}/>
                
                <h4 className="login_style">Senha:</h4>
                <input id="senha_login" className="form-control" type="password" onChange={this.handleChange} onKeyDown={(value)=>{
                  if(value.key === 'Enter')
                  {
                    this.actionLogar()
                  }
                }}/> 
                <h4 className="login_style">CPF:</h4>
                <input id="cpf_temp" className="form-control" type="text" value={cpfMask(this.state.cpf_temp)} onChange={this.handleChange}/>
                <br></br>
              </div>
            )
          }
        break;
        case 2: // caso para recuperar a senha
          {
            return(
              <div className="content">
                <h3 className="login_style">Redefinição de senha</h3>
                <h4 className="login_style">E-mail:</h4>
                <input id="email_login" className="form-control" type="text" onChange={this.handleChange} onKeyDown={(value)=>{
                  if(value.key === 'Enter')
                  {
                    this.actionLogar()
                  }
                }}/> 
                <br></br>
              </div>
            )
          }
        break;
        case 3: // caso de novo cadastro
          {
            return(
              <div className="content">
                <h4 className="login_style">E-mail:</h4>
                <input id="email_login" className="form-control" type="text" onChange={this.handleChange}/>

                <h4 className="login_style">Nome:</h4>
                <input id="nome_login" className="form-control" type="text" onChange={this.handleChange}/>

                <h4 className="login_style">Sobrenome:</h4>
                <input id="sobrenome_login" className="form-control" type="text" onChange={this.handleChange}/>

                <h4 className="login_style">CPF:</h4>
                <input id="cpf_temp" className="form-control" type="text" value={cpfMask(this.state.cpf_temp)} onChange={this.handleChange}/>

                <h4 className="login_style">Senha:</h4>
                <input id="senha_login" className="form-control" type="password" onChange={this.handleChange} onKeyDown={(value)=>{
                  if(value.key === 'Enter')
                  {
                    this.actionLogar()
                  }
                }}/>

                <h4 className="login_style">Repita a senha:</h4>
                <input id="senha_temp" className="form-control" type="password" onChange={this.handleChange} onKeyDown={(value)=>{
                  if(value.key === 'Enter')
                  {
                    this.actionLogar()
                  }
                }}/> 
                <label style={{color:'#EB7228'}}>{this.state.password !== this.state.senha_temp ? "As senhas não são iguais" : ""}</label>

                <br></br>

              </div>
            )
          }
        break;
        case 4: // sucesso na consulta
          {
            return(
              <Redirect to='/admin/contrato'/>
            )
          }
        break;
        default:
          {
            return(
              <div className="content">
                <h4 className="login_style">E-mail:</h4>
                <input id="email_login" className="form-control" type="text" onChange={this.handleChange}/>
                
                <h4 className="login_style">Senha:</h4>
                <input id="senha_login" className="form-control" type="password" onChange={this.handleChange} onKeyDown={(value)=>{
                  if(value.key === 'Enter')
                  {
                    this.actionLogar()
                  }
                }}/> 
                <br></br>
              </div>)
          }
      }
  }

  render() {
    const { cpf } = this.state
    return (
      <div className="container-fluid p-0" id="geral">
        <Snackbar
        place="bc"Codigo
        open={this.state.snackBarOpen}Codigo
        closeNotification={() => this.setState({ snackBarOpen: false })}
        close
        >
        <MuiAlert elevation={6} variant="filled" severity={this.state.snackBarColor}>
          {this.state.snackBarMessage}
        </MuiAlert>

      </Snackbar>
        <nav id="menu" className="navbar navbar-expand-xl navbar-dark  p-0">
          <div id="logo" className="log ">
            
            <Link
             activeClass="active"
             to="aboutUs"
             spy={true}
             smooth={true}
             offset={-70}
             duration={500}
            ><img src={logo_fundo_branco} id="imagem" className="img-fluid" /> </Link>

          </div>

          <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarMainToggler" aria-controls="navbarMainToggler" aria-expanded="false" aria-label="Toggle navigation" >
            <span className="navbar-toggler-icon"></span>
          </button>

          <section className="collapse navbar-collapse " id="navbarMainToggler">
            <div className="navbar-nav " id="navbar-nav">
              <ul className="navbar-nav -auto" id="navbar-ul">
                <Link
                  activeClass="active"
                  to="aboutUs"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}

                >
                  <li className="it ">
                    <a className="tex styleA" id="tvc">O que é o TVC? </a>
                  </li>
                </Link>
                <Link
                  activeClass="active"
                  to="tempoCompartilhado"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}

                >
                  <li className="it">
                    <a className="tex styleA"  id="tempo">Tempo lhado</a>
                  </li>
                </Link>
                <Link
                  activeClass="active"
                  to="rcis"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}

                >
                  <li className="it">
                    <a className="tex styleA" id="rci">RCI</a>
                  </li>
                </Link>
                <Link
                  activeClass="active"
                  to="promocoes"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}

                >
                  <li className="it">
                    <a className="tex styleA" id="promo">Promoções</a>
                  </li>
                </Link>
                <Link
                  activeClass="active"
                  to="depoimentos"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}

                >
                  <li className="it">
                    <a className="tex styleA" id="depo">Depoimentos</a>
                  </li>
                </Link>
                <Link
                  activeClass="active"
                  to="contatos"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <li className="it">
                    <a className="tex styleA" id="contato">Contato</a>
                  </li>
                </Link>

                <li className="it mn" id="wha">
                  <a className="tex styleA " target="_blank" href=" https://web.whatsapp.com/send?phone=5531999549936&text=Ol%C3%A1!%20Quero%20mais%20informa%C3%A7%C3%B5es%20sobre%20as%20promo%C3%A7%C3%B5es%20do%20Tau%C3%A1%20Caet%C3%A9.%20Poderia%20me%20ajudar?" id="whats"><i className="whats fa fa-whatsapp"></i></a>
                </li>
                <li className="it mn" id="vi">
                  <button id="vip" className="btn"  onClick={() => { this.setState({ open : true }) }} > Área Vip </button>
                </li>
              </ul>

            </div>

          </section>
        </nav>
        
        <Dialog 
          open={this.state.open}
          onClose={() => this.state.close}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle className="diologLogin" id="form-dialog-title">
            <div className="loginImg">
              <img src={logoLogin} id="imgLogin"className="img-fluid" />
            </div>
            <br></br>
            <Divider variant="middle" />
            <this.NextPage></this.NextPage>
          </DialogTitle>           
          <DialogAction className="diologLogin">
            <div className="botoes" row>
              <button className="botaoLogin " variant="contained" onClick={() => { 
                this.state.situacao === 0 ? this.setState({open : false}) : 
                this.state.situacao === 1 ? this.setState({situacao : 0}) : 
                this.state.situacao === 2 ? this.setState({situacao : 0}) : 
                this.state.situacao === 3 ? this.setState({situacao : 0}) : 
                this.setState({open : false, situacao:0})}}>Voltar</button>
              <button  className="botaoLogin " onClick= {this.actionLogar}>{
                this.state.situacao === 0 ? 'Logar' : 
                this.state.situacao === 1 ? 'Atualizar' : 
                this.state.situacao === 2 ? 'Recuperar' : 
                this.state.situacao === 3 ? 'Cadastrar' : 
                this.state.situacao === 4 ? '...' :
                'Logar'
              }</button>
            </div> 
          </DialogAction>
        </Dialog>
      </div>
    );
  }
}

const mapStateToProps = ({ login }) => {
  const { user } = login;

  return {
    user
  };
};

export default connect (mapStateToProps, {
  onLoginPress
}) (Navbar);