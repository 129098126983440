import contrato from "views/Contrato/Contrato.js";
import perfil from "views/Perfil/Perfil.js";
import reserva from "views/Reserva/index.js";
import administracao from "views/Administracao/index";
import login from "layouts/logi.js";

import React from "react";
// @material-ui/icons

import PersonIcon from '@material-ui/icons/Person';
import AssignmentIcon from '@material-ui/icons/Assignment';
import EventAvailableIcon from '@material-ui/icons/EventAvailable';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import iconeVantagens from "./assets/img/value.png";


var dashRoutes = [
  
  {
    path: "/contrato",
    name: "Contrato",
    icon: AssignmentIcon,
    component: contrato,
    layout: "/admin"
  },
  {
    path: "/perfil",
    name: "Perfil",
    icon: PersonIcon,
    component: perfil,
    layout: "/admin"
  },
  {
    path: "/reserva",
    name: "Reserva",
    icon: EventAvailableIcon,
    component: reserva,
    layout: "/admin",
  },
  {
    path: "/administracao",
    name: "Area Administrativa",
    icon: EventAvailableIcon,
    component: administracao,
    layout: "/admin",
  },
  {
    path: "/home",
    name: "Sair",
    icon: ExitToAppIcon,
    component: reserva,
    layout: "/admin",
  },
];

export default dashRoutes;
