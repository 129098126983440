import React, { Component } from 'react';

import card4 from '../../imagens/ferias de julho.jpg'
import bemEstar from '../../imagens/bem-estar.jpg'
import alegro from '../../imagens/alegro.jpg'
import promo4 from '../../imagens/alexania.jpg'
import promo5 from '../../imagens/mandala.jpg'

import './Promocoes.css';

class Promocoes extends Component {
    render() {
        return (
            <div id="promocoes" className="margimTopAlta">
                <div className="tituloPromo">
                    <p>PACOTES</p>
                </div>
                <div className="container-fluid relative " >
                    <div className="row no-gutters">
                        <div className="offset-xl-1 col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="card widthCard whiteSmoke">
                                <img className="card-img-top" src={card4} alt="Imagem de capa do card" />
                                <div className="card-body" id="card1">
                                    <h5 className="card-title fontStyle">Tauá Atibaia</h5>

                                    <button type="button" className="btn btn-outline-light roundedButton" id="btnpromo" >
                                        <a target="_blank" className="link-promo" href=" https://tauaresorts.com.br/atibaia/promocoes">Ver Pacotes</a>
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className=" col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="card widthCard">
                                <img className="card-img-top" src={bemEstar} alt="Imagem de capa do card" />
                                <div className="card-body" id="card2">
                                    <h5 className="card-title">Tauá Caeté</h5>

                                    <button type="button" className="btn btn-outline-light  roundedButton" id="btnpromo">
                                        <a target="_blank" className="link-promo" href="  https://tauaresorts.com.br/caete/promocoes">Ver Pacotes</a>
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className=" col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="card widthCard">
                                <img className="card-img-top" src={promo5} alt="Imagem de capa do card" />
                                <div className="card-body" id="card3">
                                    <h5 className="card-title">Tauá Araxá</h5>
                                    <button type="button" className="btn btn-outline-light  roundedButton" id="btnpromo">
                                        <a target="_blank" className="link-promo" href=" https://tauaresorts.com.br/araxa/promocoes">Ver Pacotes</a>
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className=" col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="card widthCard">
                                <img className="card-img-top" src={promo4} alt="Imagem de capa do card" />
                                <div className="card-body" id="card4">
                                    <h5 className="card-title">Tauá Alexânia</h5>
                                    <button type="button" className="btn btn-outline-light  roundedButton" id="btnpromo">
                                        <a target="_blank" className="link-promo" href="https://tauaresorts.com.br/alexania/promocoes">Ver Pacotes</a>
                                        
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className=" col-xl-2 col-lg-3 col-md-4 col-sm-6 col-12">
                            <div className="card widthCard ">
                                <img className="card-img-top" src={alegro} alt="Imagem de capa do card" />
                                <div className="card-body" id="card5">
                                    <h5 className="card-title">Tauá Alegro</h5>
                                    <button type="button" className="btn btn-outline-light  roundedButton" id="btnpromo">
                                        <a target="_blank" className="link-promo" href=" http://alegrohotel.com.br/promocoes/">Ver Pacotes</a>
                                        
                                    </button>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}
export default Promocoes;