import React ,{Component} from 'react'
import {Button,Row,Col,Container} from 'reactstrap'
import AOS from 'aos';
import 'aos/dist/aos.css';
import tempComp from "../../imagens/piscinaQuadrada.png"

import '../Styles/TempoCompartilhado.css'
AOS.init();
export default class TempoCompartilhado extends React.Component{
    render()
    {
        return(
          <div id="tempoCompartilhado"className="container-fluid static marginBaixo px-0 mt-0 " >
              <div className="row no-gutters">
                  <div className="col-xl-5 col-10 col-lg-6 order-md-last order-xl-first col-md-6">
              {/*Container da imagem Circular */}

                <div className="tempo relative"
                  
                >  
                  <img src={tempComp} id="tempoCompIm" className="img-fluid" />
                </div>
            </div>
            <div className="col-xl-5 col-10 lg-12 col-md-10 md-order-1 col-order-last mTop">
                <div className="divTextoCorridoTempo "> 
                  <h2 className="TituloCompartilhado content">Tempo Compartilhado</h2>
                    <p className="textoParagrafo content">
                    O sistema adotado pelo Tauá VIP Club é baseado no sistema de Tempo Compartilhado, o qual consiste na aquisição de uma ou mais semanas em um apartamento, cujo uso compartilhado é organizado de modo que a mesma unidade será partilhada por outras pessoas adquirentes das demais semanas do ano da mesma unidade. Com esse sistema você pode viajar de forma prática, inteligente e flexível.
                    </p>
                </div>
              
          </div>
          <div className="col-xl-2 col-2  col-md-2 md-order-2">
              <div className="container-fluid divBordaDireita"> 

              </div>
          </div>
        </div>

      </div>
    


        )
    }
}