import React, { Component } from "react";
import Divider from '@material-ui/core/Divider';
import { makeStyles } from '@material-ui/core/styles';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Button from '@material-ui/core/Button';

import Dialog from '@material-ui/core/Dialog';
import DialogAction from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import {api} from '../../services/api';
import { isThisTypeNode } from "typescript";

import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import {DateRangeInput} from '@datepicker-react/styled'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import { connect } from 'react-redux';
import moment from 'moment';

import './Reserv.css';
import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import TimerIcon from '@material-ui/icons/Timer';

const useStyles = makeStyles(theme => ({
    root: {
      width: '100%',
    },
    heading: {
      fontSize: theme.typography.pxToRem(15),
      fontWeight: theme.typography.fontWeightRegular,
    },
  }));

var timer_obj;
moment.locale('pt-BR');

//import ClipLoader from "react-spinners/ClipLoader";
const override = css`
display: block;
align: center;
border-color: orange;
position: fixed;
z-index: 99;
top:50%;
left:40%;
`;
const Snack = {
  message: '',
  color: '',
  icon: null
}



class Reserv extends Component {
  constructor(props){

    super(props);
     this.state = this.props.dataFromParent;
     var clientes_temp = {
      "Customer": {
          "PersonName": {
              "GivenName": "",
              "Surname": "",
              "NameTitle": {}
          },
          "Telephone": 
          [{
              "PhoneTechType": "1",
              "AreaCityCode": "",
              "PhoneNumber": ""
            },
          ],
          "Email": "",
          "Address": {
              "AddressLine": "",
              "StreetNmbr": "",
              "District": "",
              "CityName": "",
              "CityCode": "",
              "StateProv": "",
              "PostalCode": "",
              "CountryName": ""
          },
          "Document": {
              "DocType": "CPF",
              "DocID": "",
              "BirthDate": "",
              "Gender": "M"
          }
      },
      "ProfileType": "1",
      "ID": "0"
    };
    
    const clientes_blank = {
      "Customer": {
          "PersonName": {
            "GivenName": "",
            "Surname": "",
            "NameTitle": {}
          },
          "Telephone": 
          [{
              "PhoneTechType": "1",
              "AreaCityCode": "",
              "PhoneNumber": ""
            },
          ],
          "Email": "",
          "Address": {
            "AddressLine": "",
            "StreetNmbr": "",
            "District": "",
            "CityName": "",
            "CityCode": "",
            "StateProv": "",
            "PostalCode": "",
            "CountryName": ""
          },
          "Document": {
              "DocType": "CPF",
              "DocID": "",
              "BirthDate": "",
              "Gender": "M"
          }
      },
      "ProfileType": "1",
      "ID": "0"
    };
    
    }
    
    Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    loadingSnackBar = (snack) =>  { //Função para carregar a snack bar 
    this.setState({
        snackBarColor: snack.color,
        snackBarMessage: snack.message,
        snackbarIcon: snack.icon,
        snackBarOpen: true
    });
    setTimeout(
        function () {
            this.setState({ snackBarOpen: false });
        }.bind(this),
        5500
    );
    }

    componentWillUnmount()
    {
      if(this.state.loading == true)
      {
        this.setState({loading:false})
        clearTimeout(timer_obj);
      }
    }

    getCPF(cpfTarget) {

      this.setState({loading:true})

      // timer_obj = setTimeout(() => {
      //   if(this.state.loading == true)
      //   {
      //     this.setState({loading:false})
      //     Snack.message ="Falha na comunicação com o servidor"
      //     Snack.color = "warning"
      //     Snack.icon = ErrorIcon
      //     this.loadingSnackBar(Snack)
      //   }
      // }, 20000)

      let temp = JSON.parse(JSON.stringify(this.state.clientes));

      api.get('/perfil?cpf='+cpfTarget, { headers: { token: this.props.user.access_token, email: this.props.user.email}})
      .then(response => { 

        console.log("CPF: ",response.data)

        temp[parseInt(this.state.expanded)].ID = response.data.Profile.ID
        temp[parseInt(this.state.expanded)].ProfileType =  response.data.Profile.ProfileType
        Object.assign(temp[parseInt(this.state.expanded)].Customer ,response.data.Profile.Customer)
        temp[parseInt(this.state.expanded)].Customer.Telephone = [].concat(response.data.Profile.Customer.Telephone)
        temp[parseInt(this.state.expanded)].Customer.Document.BirthDate = response.data.Profile.Customer.Document.BirthDate   //.replace('-','/') 

        this.setState({
          loading:false,
          clientes: temp
        })
        
      })
      .catch(function (error) {  
        console.log("ERROR:",error)
        clearTimeout(timer_obj);
      })
      this.setState({loading:false})
    }

    enviar = event =>{

      this.setState({loading:true})
      
      timer_obj = setTimeout(() => {
        if(this.state.loading == true)
        {
          this.setState({loading:false})
          Snack.message ="Falha na comunicação com o servidor"
          Snack.color = "warning"
          Snack.icon = ErrorIcon
          this.loadingSnackBar(Snack)
        }
      }, 20000)
         
      const reservar =  {
        observacao: this.state.observacao,
        dataChegada: this.state.dataChegada,
        dataSaida:this.state.dataSaida,
        cpf:this.state.cpf,
        contratoId:this.state.idContrato,
        codigoPacote:this.state.codigoPacote, 
        bandeiraCartao:this.state.bandeiraCartao,
        cartaoNumero:this.state.cartaoNumero,
        validadeCartao:this.state.validadeCartao,
        cvv:this.state.cvv,
        parcelas:this.state.parcelas,
        cpfCartao:this.state.cpfCartao,
        PrecoUnitario:this.state.precoTotal,
        nomeCartao:this.state.nomeCartao,
        clientes:this.state.clientes,
        tipoEspacos:this.state.tipoEspacos,
        };
        console.log("data:", this.state.dataChegada)
      this.setState({loading:true})
     
        api.post('/reserva', reservar, { headers: { token: this.props.user.access_token, email: this.props.user.email}})
        .then(response => {
          console.log(response.data)
          
            this.setState({
              loading:false,
              sucesso: response.data.UniqueID.ID,
              mensagem: response.data.Observacao,
              open : true,
            })
            
        })
        .catch( error => 
        {  
          //alert({error}.erro);
          console.log("dados: ", reservar)
          //console.log("ERROR:", error)
          Snack.message ={error}.error.response.data.message
          Snack.color = "warning"
          Snack.icon = ErrorIcon
          this.loadingSnackBar(Snack)
          this.setState({loading:false})
        })
      
    }

    handleChange = event =>{

      console.log('event', event.target)
      let temp = JSON.parse(JSON.stringify(this.state.clientes));
      var obj = null;

      switch (event.target.id) 
      {
        case 'obs': 
        this.setState(
          {
              observacao : event.target.value
          }
        )
        break;
        case 'bandeiraCartao' :
          this.setState(
            {
              bandeiraCartao : event.target.value
            }
          )
        break;
        case 'cartaoNumero' :
          this.setState(
            {
              cartaoNumero : event.target.value.replace('.','').replace('/','').replace('-','').replace(' ','')
            }
          )
        break;
        case 'validadeCartao' :
          this.setState(
            {
              validadeCartao : event.target.value.replace('.','/').replace('-','/')
            }
          )
        break;
        case 'cvv' :
          this.setState(
            {
              cvv : event.target.value.replace('.','').replace('/','').replace('-','')
            }
          )
        break;
        case 'parcelas' :
          this.setState(
            {
              parcelas : event.target.value
            }
          )
        break;
        case 'cpfCartao' :
          this.setState(
            {
              cpfCartao : event.target.value.replace('.','').replace('.','').replace('-','')
            }
          )
        break;
        case 'nomeCartao' :
          this.setState(
            {
              nomeCartao : event.target.value
            }
          )
        break;
        case 'nome' :
          {
            temp[parseInt(this.state.expanded)].Customer.PersonName.GivenName = event.target.value
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'sobrenome' :
          {
            temp[parseInt(this.state.expanded)].Customer.PersonName.Surname = event.target.value
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'nascimento' :  
          {                
            temp[parseInt(this.state.expanded)].Customer.Document.BirthDate = event.target.value
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'sexo' :
          {
            temp[parseInt(this.state.expanded)].Customer.Document.Gender = event.target.value
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'ddd' :
          {
            temp[parseInt(this.state.expanded)].Customer.Telephone.AreaCityCode = event.target.value.replace('.','').replace('/','').replace('-','')
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'tel' :
          {
            temp[parseInt(this.state.expanded)].Customer.Telephone.PhoneNumber = event.target.value.replace('.','').replace('/','').replace('-','')
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'email' :
          {
            temp[parseInt(this.state.expanded)].Customer.Email = event.target.value
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'rua' :
          {
            temp[parseInt(this.state.expanded)].Customer.Address.AddressLine = event.target.value
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'numero' :
          {
            temp[parseInt(this.state.expanded)].Customer.Address.StreetNmbr = event.target.value
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'bairro' :
          {
            temp[parseInt(this.state.expanded)].Customer.Address.District = event.target.value
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'cidade' :
          {
            temp[parseInt(this.state.expanded)].Customer.Address.CityName = event.target.value
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'cep' :
          {
            temp[parseInt(this.state.expanded)].Customer.Address.PostalCode = event.target.value.replace('.','').replace('/','').replace('-','')
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'estado' :
          {
            temp[parseInt(this.state.expanded)].Customer.Address.StateProv = event.target.value
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'pais' :
          {
            temp[parseInt(this.state.expanded)].Customer.Address.CountryName = event.target.value
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        case 'documento' :
          {
            temp[parseInt(this.state.expanded)].Customer.Document.DocType = event.target.value
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        
        case 'numdoc' :
          {
            temp[parseInt(this.state.expanded)].Customer.Document.DocID = event.target.value.replace('.','').replace('/','').replace('-','')
            if((event.target.value.replace('.','').replace('/','').replace('-','').length === 11) && (temp[parseInt(this.state.expanded)].Customer.Document.DocType === 'CPF'))
            {
              this.getCPF(event.target.value.replace('.','').replace('/','').replace('-',''))
            }
            this.setState(
            {
              clientes: temp
            })
          }
        break;
        default:
          break;
      }
    }

    expandedControl = panel => (event, isExpanded) => {
      var t = (isExpanded ? panel : false);
      this.setState({expanded: t})
    };
       
    titulo = (i) => {
      if((i.value+1)>parseInt(this.state.pax))
      {
        return(
          <Typography className={useStyles.heading} >Criança {(i.value+1) - parseInt(this.state.pax)}</Typography>
        )
      }
      else{
        return(
          <Typography className={useStyles.heading} >Adulto {(i.value+1)}</Typography>
        )
      }
    }


    render() {
      const { clientes } = this.state
        return (
            <div>
              <Snackbar
                place="bc"
                open={this.state.snackBarOpen}
                closeNotification={() => this.setState({ snackBarOpen: false })}
                close
                >
                <MuiAlert elevation={6} variant="filled" severity={this.state.snackBarColor}>
                  {this.state.snackBarMessage}
                </MuiAlert>

              </Snackbar>
              <div className="sweet-loading">
                <ClipLoader
                  css={override}
                  size={150}
                  color={"#123abc"}
                  loading={this.state.loading}
                />
              </div>
                <h3>Resumo:</h3>
                <div className="form-row"> 
                    <div className="form-group col-md-3">
                        <label>Contrato ID</label>
                        <input readOnly type="text" className="form-control" id="contrato" value={this.state.idContrato}/>
                    </div>
                    <div className="form-group col-md-3">
                        <label>Codigo do Pacote</label>
                        <input readOnly type="text" className="form-control" id="codigoPacote" value={this.state.codigoPacote}/>
                    </div>
                    <div className="form-group col-md-6">
                        <label>Hotel</label>
                        <input readOnly type="text" className="form-control" value={this.state.hotel}/>
                    </div>
                </div>
                <div className="form-row">
                    <div className="form-group col-md-3">
                        <label>Data Chegada</label>
                        <input readOnly type="text" className="form-control" id="chegada" value={moment(this.state.dataChegada).format('DD/MM/YYYY') }/>
                    </div>
                    <div className="form-group col-md-3">
                        <label>Data Saída</label>
                        <input readOnly type="text" className="form-control" id="Saida"  value={moment(this.state.dataSaida).format('DD/MM/YYYY') }/>
                    </div>    
                    <div className="form-group col-md-3">
                        <label>Pontos Utilizados</label>
                        <input readOnly type="text" className="form-control" id="pontos"  value={this.state.pontos}/>
                    </div> 
                </div>
                <br></br>
                <div className="form-group col" >
                  <div className="form-group row">
                  
                    <label className=" col-1">Item</label>
                    <label className=" col-2">Cod.</label>
                    <label className=" col-3">Nº Adultos</label>
                    <label className=" col-3">Nº Crianças</label>
                    <label className=" col-3">Nº Crianças Free</label>
                  </div>
                  <Table
                    aria-label="a dense table" 
                  >
                    <TableBody>
                    {
                      this.state.tipoEspacos.map(
                        (quarto, i) => {
                          return(
                            <TableRow key={i}>
                              <div className="form-group row">
                                <TableCell className=" col-1" > {i+1}</TableCell>
                                <TableCell className=" col-2" >{quarto.codigo}</TableCell>
                                <TableCell className=" col-3" >{quarto.pax}</TableCell>
                                <TableCell className=" col-3" >{quarto.chd}</TableCell>
                                <TableCell className=" col-3" >{quarto.chdFree}</TableCell>
                              </div>
                            </TableRow>
                          )
                        }
                      )
                    }
                    </TableBody>
                  </Table>
                </div>

                <br></br>
                <Divider variant="middle" />
                <br></br>

                <h3>Hóspedes:</h3>
                {
                  clientes.map( (cliente, i) => {
                    console.log('cliente: ', cliente);
                    return ( 
                  <div key={i} style={{margin:'2px'}}>
                    <ExpansionPanel id={i.toString()} expanded={this.state.expanded === i.toString()} onChange={
                      this.expandedControl(i.toString())
                    }>
                      <ExpansionPanelSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                      > 
                        <this.titulo value={i}></this.titulo>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                      <Typography>
                              <h6>Documento:</h6>
                              <div className="form-row">
                                    {
                                    i < this.state.pax ? (  
                                      <div className="form-group col-md-2">                       
                                    <label>Tipo</label>
                                      <select id="documento" className="form-control" onChange={this.handleChange}>
                                        <option selected>CPF</option>
                                      </select>
                                      </div> 
                                      ) : (
                                        <div className="form-group col-md-1">
                                      <label>Tipo</label>
                                        <select id="documento" className="form-control" onChange={this.handleChange}>
                                          <option selected>CPF</option>
                                          <option>RG</option>
                                          <option>CERT NASC</option>
                                        </select>
                                        </div> 
                                      )
                                    }
                                  <div className="form-group col-md-5">
                                      <label>Número do Documento</label>
                                      <input type="text" maxLength='11' className="form-control" id="numdoc" onChange={this.handleChange} />
                                  </div>  
                              </div>
                              <h6>Dados pessoais:</h6>
                              <div className="form-row">
                                  <div className="form-group col-md-6">
                                      <label>Nome</label>
                                      <input type="text" className="form-control" value={typeof(cliente.Customer.PersonName.GivenName) === 'object' ? '' :cliente.Customer.PersonName.GivenName} id="nome" onChange={this.handleChange} />
                                  </div> 
                                  <div className="form-group col-md-6">
                                      <label>Sobrenome</label>
                                      <input type="text" className="form-control" value={typeof(cliente.Customer.PersonName.Surname) === 'object' ? '' :cliente.Customer.PersonName.Surname} id="sobrenome" onChange={this.handleChange} />
                                  </div>    
                                  <div className="form-group col-md-4">
                                      <label>Data de Nascimento</label>
                                      <input type="text" className="form-control" value={typeof(cliente.Customer.Document.BirthDate) === 'object' ? '' :cliente.Customer.Document.BirthDate} id="nascimento" onChange={this.handleChange} />
                                  </div>
                                  <div className="form-group col-md-3">
                                      <label>Sexo</label>
                                      <select id="sexo" className="form-control" value={typeof(cliente.Customer.Document.Gender) === 'object' ? '' :cliente.Customer.Document.Gender} onChange={this.handleChange}>
                                        <option selected>M</option>
                                        <option>F</option>
                                        <option>N</option>
                                      </select>  
                                  </div>  
                              </div>
                              <h6>Telefone:</h6>
                              <div className="form-col">
                                {
                                  cliente.Customer.Telephone.map((numero) =>(
                                    <div className="form-row">
                                      <div className="form-group col-md-2">
                                          <label>DDD</label>
                                          <input type="tel" className="form-control" value={typeof(numero.AreaCityCode) === 'object' ? '' :numero.AreaCityCode} id="ddd" onChange={this.handleChange} />
                                      </div> 
                                      <div className="form-group col-md-5">
                                          <label>Número de telefone</label>
                                          <input type="tel" className="form-control" value={typeof(numero.PhoneNumber) === 'object' ? '' :numero.PhoneNumber} id="tel" onChange={this.handleChange} />
                                      </div>  
                                    </div>
                                  ))
                                }
                                  <div className="form-group col-md-6">
                                      <label>E-mail</label>
                                      <input type="email" className="form-control" id="email"  value={typeof(cliente.Customer.Email) === 'object' ? '' : cliente.Customer.Email} onChange={this.handleChange} />
                                  </div>    
                              </div>
                              <h6>Endereço:</h6>
                              <div className="form-row">
                                  <div className="form-group col-md-5">
                                      <label>Rua</label>
                                      <input type="text" className="form-control" value={typeof(cliente.Customer.Address.AddressLine) === 'object' ? '' :cliente.Customer.Address.AddressLine} id="rua" onChange={this.handleChange} />
                                  </div> 
                                  <div className="form-group col-md-2">
                                      <label>Número</label>
                                      <input type="tel" className="form-control" value={typeof(cliente.Customer.Address.StreetNmbr) === 'object' ? '' :cliente.Customer.Address.StreetNmbr} id="numero" onChange={this.handleChange} />
                                  </div>  
                                  <div className="form-group col-md-5">
                                      <label>Bairro</label>
                                      <input type="text" className="form-control" value={typeof(cliente.Customer.Address.District) === 'object' ? '' :cliente.Customer.Address.District} id="bairro" onChange={this.handleChange} />
                                  </div>
                                  <div className="form-group col-md-3">
                                      <label>Cidade</label>
                                      <input type="text" className="form-control" value={typeof(cliente.Customer.Address.CityName) === 'object' ? '' :cliente.Customer.Address.CityName} id="cidade" onChange={this.handleChange} />
                                  </div>
                                  <div className="form-group col-md-4">
                                      <label>CEP</label>
                                      <input type="text" maxLength="10" className="form-control" value={typeof(cliente.Customer.Address.PostalCode) === 'object' ? '' :cliente.Customer.Address.PostalCode} id="cep" onChange={this.handleChange} />
                                  </div>
                                  <div className="form-group col-md-2">
                                      <label>Estado</label>
                                      <input type="text" maxLength="2" className="form-control" value={typeof(cliente.Customer.Address.StateProv) === 'object' ? '' :cliente.Customer.Address.StateProv} id="estado" onChange={this.handleChange} />
                                  </div>
                                  <div className="form-group col-md-3">
                                      <label>País</label>
                                      <input type="text" className="form-control" value={typeof(cliente.Customer.Address.CountryName) === 'object' ? '' :cliente.Customer.Address.CountryName} id="pais" onChange={this.handleChange} />
                                  </div>    
                              </div>
                              
                          </Typography>
                      </ExpansionPanelDetails>
                  </ExpansionPanel>           
                </div>
                  )})
            }
            <br></br>
                <Divider variant="middle" />
            <br></br>

            <h3>Observações:</h3>
            <div className="form-group ">
              <input type="textarea" className="form-control" id="obs" onChange={this.handleChange} />
              <label>*Solicitar berço, banheira, etc</label>
            </div>

            <br></br>
                <Divider variant="middle" />
            <br></br>

            <h3>Alimentação:</h3>
            <div className="form-group ">

            <div className="form-row">
              <label className=" col-4"> Nome </label>
              <label className=" col-4"> Preço Unitário </label>
              <label className=" col-">Preço Total </label>
            </div>

            <Table
                tableHeaderColor="info"
                aria-label="simple table" fullWidth
              >
                <TableBody>
                  {
                    this.state.alimentacao.map(
                      ali =>(
                        <TableRow key={ali.Codigo}>
                          <div className="form-group row">
                            <TableCell className=" col-4">  {ali.Nome}</TableCell>
                            <TableCell className=" col-4"> R$ {ali.PrecoUnitario}</TableCell>
                            <TableCell className=" col-4"> R$ {ali.PrecoTotal}</TableCell>
                          </div>
                        </TableRow>
                      )
                    )
                  }
                </TableBody>
              </Table>

              <label>Valor total da alimentação: </label>
              <input readOnly type="textarea" className="form-control" id="obs" value={"R$"+ this.state.precoTotal} />
              
            </div>

            <br></br>
            <Divider variant="middle" />
            <br></br>

            <h3>Pagamento:</h3>
              <div className="row">
                <div className="form-group col-md-3">
                  <label htmlFor="inputZip">Bandeira</label>
                        <div>
                          <select id="bandeiraCartao" className="form-control" onChange={this.handleChange}>
                          {
                            this.state.bandeiras.map(
                              bandeira =>(
                                <option selected value = {bandeira.Codigo}> {bandeira.Descricao} </option>
                                )
                            )
                          }
                          </select>
                      </div>
                </div>
                  
                <div className="form-group col-md-5">
                    <label>Número do Cartão</label>
                    <input type="text" maxLength="16" className="form-control" id="cartaoNumero"  onChange={this.handleChange}/>        
                </div>

                <div className="form-group col-md-2">
                    <label>Validade </label>
                    <input type="text" maxLength="8" className="form-control" id="validadeCartao" onChange={this.handleChange} />        
                </div>

                <div className="form-group col-md-2">
                    <label>CVV</label>
                    <input type="text" maxLength="3" className="form-control" id="cvv"  onChange={this.handleChange}/>        
                </div>
              </div>
              
              <div className="row">
                <div className="form-group col-md-3">
                    <label>Parcelas</label>
                    <select id="parcelas" className="form-control" onChange={this.handleChange}>
                        <option selected>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                        <option>5</option>
                      </select>        
                </div>
              </div>
              <div className="row">
                <div className="form-group col-md-4">
                    <label>Nome no Cartão</label>
                    <input type="text" className="form-control" id="nomeCartao"  onChange={this.handleChange}/>        
                </div>
                <div className="form-group col-md-4">
                    <label>CPF do Cartão</label>
                    <input type="text" className="form-control" id="cpfCartao"  onChange={this.handleChange}/>        
                </div>
                <div className="form-group col-md-4">
                    <label>Preço Unitário</label>
                    <input readOnly type="text" className="form-control" id="PrecoUnitario"  value={"R$"+ this.state.precoTotal}/>        
                </div>
              </div>
            
            <br></br>
            
            <div className="" >
              <div className="botoes">
                <button className="botaoCalc " variant="contained" onClick={() => {           
                    this.setState({tela:1})
                    setTimeout(() => {
                    this.props.parentCallback(this.state);
                    },500)
                    }} >Voltar</button>
                 
                  <div style={{padding:10}}></div>

                <button className="botaoCalc " variant="contained" onClick={this.enviar}
                    >Reservar</button>
              </div>
            </div>
            <Dialog
              open={this.state.open}
              onClose={() => this.state.close}
              aria-labelledby="form-dialog-title"
                >
              <DialogTitle id="form-dialog-title">
                <div className="form-group col">
                  <h5>Pré reserva feita com sucesso</h5>
                  <label className="form-control">Número da pré reserva: {this.state.sucesso}</label>
                  <h6>{this.state.mensagem}</h6>
                </div>
                
              </DialogTitle>
              <DialogAction className="DialogCalc">
                <div className="botoes" row>
                  <button className="botaoCalc " variant="contained" 
                          onClick={() => {    
                            this.props.scrollToTop();       
                            this.setState({tela:1})
                            this.setState({open : false,})
                            setTimeout(() => {
                              this.props.parentCallback(this.state);
                              },500)
                          }} 
                         >Concluir</button>
                </div>
              </DialogAction>
            </Dialog>
          </div>
        );
    }
}


const mapStateToProps = ({ login }) => {
  const { user } = login;

  return {
    user
  };
};

export default connect (mapStateToProps, {
  
}) (Reserv);
