import React , {Component} from 'react'
import '../Styles/Rci.css'

import lateral from '../../imagens/lateralOndas.png'
import barraBackground from '../../imagens/barraRci.png'
import { Container, Row, Col,Button} from 'reactstrap';
import barraRci from '../../imagens/barraRci.png'


import CarouselNovo from './CarouselNovo'



export default class Rci extends Component{
    render()
    {
        return(
            <div id="rcis" className="container-fluid  px-0 relative mtTeste  py-0">
                <div className="row no-gutter">
                    <div className="col-xl-1 col-2 col-md-2 col-lg-2">
                        <div className="divBorda">
                              
                        </div>
                    </div>
                    <div className="offset-xl-1 col-xl-4 offset-md-0 col-lg-10 col-md-10  col-10 col-sm-12 col-lg-10 text-center mt-3">
                          <div className="divTexto ">
                              <h1 className="tituloRci">
                                RCI
                              </h1>
                              <p className="textoCorrido">
                              Para permitir que você troque suas semanas em diversos destinos do mundo, o Tauá Vip Clup se associou a RCI que é a maior operadora de intercâmbio de férias e líder global do segmento. A RCI conta com mais de 4 mil resorts e hotéis afiliados e cerca de 3,2 milhões de famílias associadas ao redor do mundo. No Brasil são mais de 70 hotéis no sistema, em 17 Estados de Norte a Sul do país.                              </p>
                                <a href="https://www.rci.com/pre-rci-pt_BR/index.page" target="_blank" className="myButton">Conheça mais</a>
                 
                          </div>
                    </div> 
                    <div className="col-0 col-xl-1 col-lg-0">

                    </div>
                    <div className="  col-xl-5 col-12 col-md-12 topNeg col-lg-12">
                          <div className="rcorners">
                              <CarouselNovo></CarouselNovo>
                          </div>
                    </div>
                  </div>
                </div>
        )
    }
}