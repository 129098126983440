/*!

=========================================================
* Material Dashboard PRO React - v1.8.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch, Redirect } from "react-router-dom";

import ReduxThunk from 'redux-thunk';
import 'bootstrap/dist/css/bootstrap.min.css';

import AuthLayout from "layouts/Auth.js";
import RtlLayout from "layouts/RTL.js";
import AdminLayout from "layouts/Admin.js";

import login from "layouts/logi.js";
import Site from "./views/Site/Site"

import "assets/scss/material-dashboard-pro-react.scss?v=1.8.0";

import dashRoutes from "routes.js";
import { Provider } from 'react-redux';
import { createStore, applyMiddleware } from "redux";
import reducers from './reducers';



export const hist = createBrowserHistory();
const store =createStore( reducers, {}, applyMiddleware(ReduxThunk))


ReactDOM.render(
  <Provider store = {store}>
    <Router history={hist}>

      <Switch>    
        <Route path="/home" component={Site} />
        <Route path="/admin/reserva" component={AdminLayout} />
        <Route path="/admin/perfil" component={AdminLayout} />
        <Route path="/admin/contrato" component={AdminLayout} />
        <Route path="/admin/administracao" component={AdminLayout} />
        <Redirect from="/" to="/home" />
      </Switch>

    </Router>
  </Provider>,

  document.getElementById("root")
);
