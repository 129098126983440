import React from "react";

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem
} from "reactstrap";

// core components
const items = [
  {
    src: require("../../imagens/family1.jpg"),
    content: (
      <CardBody>
        <h5 className="card-description">
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer euismod pharetra nisl, eget efficitur libero porttitor faucibus. Suspendisse metus magna, vulputate eu condimentum eu, pellentesque et ex."
        </h5>
        <CardFooter>
          <CardTitle tag="h4">Chase Jackson</CardTitle>
        </CardFooter>
      </CardBody>
    ),
    altText: "",
    caption: ""
  },
  {
    src: require("../../imagens/family1.jpg"),
    content: (
      <CardBody>
        <h5 className="card-description">
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer euismod pharetra nisl, eget efficitur libero porttitor faucibus."
        </h5>
        <CardFooter>
          <CardTitle tag="h4">Robin Leysen</CardTitle>
        </CardFooter>
      </CardBody>
    ),
    altText: "",
    caption: ""
  },
  {
    src: require("../../imagens/family2.jpg"),
    content: (
      <CardBody>
        <h5 className="card-description">
          "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer euismod pharetra nisl."
        </h5>
        <CardFooter>
          <CardTitle tag="h4">Cristi Jora</CardTitle>
        </CardFooter>
      </CardBody>
    ),
    altText: "",
    caption: ""
  }
];

function SectionTestimonials() {
  // carousel - TESTIMONIALS 2
  const [activeIndex, setActiveIndex] = React.useState(0);
  const [animating, setAnimating] = React.useState(false);
  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === items.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? items.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  return (
    <>
      <div
        className="container-fluid relative"
        id="testimonials"
      >
          <Container>
          <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title" style={{ color: "#eb7228"}}>Depoimentos</h2>
                <h5 className="description">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Integer euismod pharetra nisl, eget efficitur libero porttitor faucibus. 
                </h5>
              </Col>
            </Row>
            <div className="page-carousel">
              <Carousel
                activeIndex={activeIndex}
                next={next}
                previous={previous}
              >
                {items.map(item => {
                  return (
                    <CarouselItem
                      onExiting={onExiting}
                      onExited={onExited}
                      key={item.src}
                    >
                      <Row>
                        <Col className="ml-auto mr-auto" md="1" />
                        <Col className="ml-auto mr-auto" md="5" style={{padding: "15px"}}>
                            <div>
                              <img alt="..." className="img-rounded" src={item.src}
                               style={{height: "50%" }}/>
                            </div>
                        </Col>
                        <Col md="6">
                          <Card className="card-plain">
                          {item.content}
                          </Card>
                        </Col>
                      </Row>
                    </CarouselItem>
                  );
                })}
                <a
                  className="left carousel-control carousel-control-prev"
                  data-slide="prev"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    previous();
                  }}
                  role="button"
                >
                  <span className="fa fa-angle-left" />
                  <span className="sr-only">Previous</span>
                </a>
                <a
                  className="right carousel-control carousel-control-next"
                  data-slide="next"
                  href="#pablo"
                  onClick={e => {
                    e.preventDefault();
                    next();
                  }}
                  role="button"
                >
                  <span className="fa fa-angle-right" />
                  <span className="sr-only">Next</span>
                </a>
              </Carousel>
            </div>
          </Container>
        </div>
    </>
  );
}

export default SectionTestimonials;
