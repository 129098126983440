import React from 'react'
import backgroundDepoimento from '../../imagens/backgroundDepoimentos.png'
import '../Styles/Depoimentos.css'
import Section from './SectionTestimonials'
import Slides from './SlidesDepoimento'
export default class Depoimentos extends React.Component{
    render()
    {
        return(
            <div id="depoimentos" className="container-fluid relative text-center px-0  mt-0"
            >      
                    <div className="col offset-xl-1">
                        <h1 className="TituloDepoimentos">
                            DEPOIMENTOS
                        </h1>
                    </div>
                  
                
               
                        <div className="divDepoimentos">
                                <div className="divSlider  align-self-center">
                                    <Slides></Slides>-
                                </div>
                        </div>

                    
            </div>
    
        )
    }
}