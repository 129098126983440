import React from 'react';
import { Col, Row, Button, Form, FormGroup, Label, Input } from 'reactstrap';
import '../Styles/ContactUs.css'
const styleInput = {
  height: "3rem",
  borderRadius: "15px",

}
const Titulo = {
  fontFamily: "CoHeadlineBold",
  textWeight: "bold",
  textAlign: "center",
  fontSize: "35px",
  color: "purple",
}
const state = {
  nome: '',
  email: '',
  telefone: '',
  mensagem: '',
}
export default class ContactUs extends React.Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleChange(e) {
      if(e.target.id==="campoPessoa")
      {
          this.setState(
            {
              nome : e.target.value
            } 
          )
      }
      else if(e.target.id==="telefone"){
          this.setState(
            {
              telefone : e.target.value
            }
          )
      }
      else if(e.target.id==="campoEmail")
      {
          this.setState (
            {
              email : e.target.value
            }
          )
      }else if(e.target.id==="mensagem"){
        this.setState(
          {
            mensagem : e.target.value
          }
        )
      }
  }
  handleSubmit(){
    alert(this.state.mensagem+ " " + this.state.nome + " " + this.state.email + " " + this.state.telefone)
  }

  state = { ...state }
  render() {
    return (
      <Form id='contatos' className="container text-left">
        <div className="text-center Titulo">
          ENTRE EM CONTATO CONOSCO
      </div>
        <Row form>
          <Col md={12}>
            <FormGroup>
              <Label for="examplaName">Nome*</Label>
              <Input value={this.state.nome} onChange={this.handleChange} type="text" name="nomePessoa" id="campoPessoa" style={styleInput} />
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label for="exampleTelefone">Telefone*</Label>
              <Input onChange={this.handleChange} value={this.state.telefone} type="text" name="tele" id="telefone" placeholder="" style={styleInput} />
            </FormGroup>
          </Col>
        </Row>
        <FormGroup>
          <Col md={12} className="tiraMargem">
            <FormGroup>
              <Label for="examplaName">Email*</Label>
              <Input onChange={this.handleChange} value= {this.state.email} type="email" name="email" id="campoEmail" style={styleInput} />
            </FormGroup>
          </Col>

        </FormGroup>
        <FormGroup>
          <Label for="exampleText">Mensagem*</Label>
          <Input value={this.state.mensagem} onChange={this.handleChange}  type="textarea" name="text" id="mensagem" rows="6" />
        </FormGroup>
        <FormGroup>
          <Row className="row text-center  ">
            <a onClick={this.handleSubmit} className="ButtonTeste">ENVIAR</a>
          </Row>

        </FormGroup>
      </Form>
    );
  }
}
