import React, { Component } from 'react';

import './Navbar.css';

import banner2 from '../../imagens/banner4.png';
import Taua_topo1 from '../../imagens/prancheta6.png';
import Taua_topo2 from '../../imagens/piscinataua.png';
import Taua_topo3 from '../../imagens/abacaxi.png';
import { Link } from 'react-scroll'

import './Carousel.css';


 class Carousel extends Component{
     render(){
         return( 
          <div>
            
            <div id="carouselExampleSlidesOnly" className="carousel slide" data-ride="carousel">
            
            <a className="seta d-none d-md-block" href="#">
              <div className="conhecaMais">
              <Link
                  activeClass="active"
                  to="aboutUs"
                  spy={true}
                  smooth={true}
                  offset={-70}
                  duration={500}
                >
                  <p className="conhecaMais">Conheça</p>
                  <p className="conhecaMais"> Mais</p>
                </Link>
                </div>
              
              <i className="fa fa-angle-double-down fa-9x" id="imgSeta"></i>          
            </a> 
           
            <img src={banner2} id="im" className="img-fluid" />
              <div className="carousel-inner" id="car">
                <div className="carousel-item active">
                  <img src={Taua_topo1} className="d-block w-100 filter"id="imagem-slide" alt="..."/>
                  <div className="carousel-caption d-none d-xl-block">
                  <h1>Que tal poder contar com as férias dos sonhos não apenas em um dos melhores Resorts do Brasil, mas opções em uma rede mundial de hotéis de altíssimo nível?  </h1>                  </div>
                </div>
                <div className="carousel-item">
                  <img src={Taua_topo2} className="d-block w-100" id="imagem-slide"alt="..."/>
                  <div className="carousel-caption d-none d-xl-block">
                    <h1> O Tauá Vip Club é isso! O Clube de Férias da rede Tauá é a melhor maneira de garantir anualmente as férias de toda a sua família. </h1>
                  </div>
                </div>
                <div className="carousel-item">
                  <img src={Taua_topo3} className="d-block w-100" id="imagem-slide"alt="..."/>
                  <div className="carousel-caption d-none d-xl-block">
                    <h1>São mais de 4.500 hotéis ao redor do mundo e em mais de 100 destinos nacionais. Saiba como! </h1>
                  </div>
                </div>
              </div>
              
            </div> 
            
                     
          </div>
         );
     }
}
export default Carousel;