import React ,{Component} from 'react'
import backgroundContato from '../../imagens/backgroundContato.png'
import Contato from './ContactUs'
import '../Styles/SectionForm.css'
export default class SectionForm extends Component{
    render()  
    {
        const imageBg =[
            require('../../imagens/backgroundDepoimentos.png')
        ]
        const backImage= {  
            backgroundPosition : "center",
            
            backgroundImage :"url ("+backgroundContato+")",
            position: "relative",
            width: "100%",
            backgroundSize : "cover",
        }
        const backCol = {
        
         
        }
        const aligCenter = {
            textAlign: "center"
        }
        return(
                <div className="container-fluid containerBgImage px-0 static mt-5" id="contato" > 
                        
                        <div className="row no-gutters">
                            <div className="col-xl-2 barraEsquerda col-1"> 

                            </div>
                            <div className="col-xl-8 col-10"  style={backCol}>

                                    <Contato>

                                    </Contato>
                            </div>
                            
                            <div className="col-xl-2 divBordaDireitaZ col-1">

                            </div>
                        </div>
                </div>
        )
    }
}
