import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_FAIL
} from '../actions/types';

const INITIAL_STATE = {
    loading_user: false,
    error_user: false,
    user: null,
};

export default ( state = INITIAL_STATE, action ) => {
    console.log(action);

    switch ( action.type ) {

        case LOGIN_USER:
            return { ...state, error_user: false, loading_user: true };

        case LOGIN_USER_SUCCESS:
            return { ...state, error_user: false, loading_user: false, user: action.payload };

        default:
            return state;
    }
}