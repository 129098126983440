// @flow
import * as React from 'react';
import withInstagramFeed from 'origen-react-instagram-feed';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import ButtonBase from '@material-ui/core/ButtonBase';
import compose from 'recompose/compose';
import '../Styles/Instagrid.css'
const styles = () => ({
  wrapper: {},
  image: {
    position : 'static',
    width: '100%',
    height: '100%',
  },
});

export type Props = {
  media?: Array<{
    displayImage: string,
    id?: string,
    postLink?: string,
    accessibilityCaption?: string,
  }>,
  account: string,
  classes: { [$Keys<$Call<typeof styles>>]: string },
  status: 'completed' | 'loading' | 'failed',
};

const InstaGrid = ({ classes, media, account, status}: Props) => {
  return (
  <div className="static">
    <Grid  container spacing={32} className={classes.wrapper}>
      {media &&
        status === 'completed' &&
        media.map(({ displayImage, id, postLink, accessibilityCaption }) => (
          <Grid  item xs={12} sm={12} md={6} lg={6} xl={6}key={id || displayImage}>
            <ButtonBase
                target="_blank"
              href={  postLink || `https://www.instagram.com/${account}/`}
            >
            <div className="divInsta">
              <img
                src={displayImage}
                alt={accessibilityCaption || 'Instagram picture'}
                className="sizeImg"
              />
            </div>
            </ButtonBase>
          </Grid>
        ))}
      {status === 'loading' && <p>loading...</p>}
      {status === 'failed' && <p>Check instagram here</p>}
    </Grid>
  </div>
  );
};

InstaGrid.defaultProps = {
  media: undefined,
};

export default compose(
  withInstagramFeed,
  withStyles(styles),
)(InstaGrid);