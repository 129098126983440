import React, {Component} from 'react';
import './Site.css';
import FooterSite from './FooterSite';
import Navbar from './Navbar';
import Carousel from './Carousel';
import underConstruction from '../../assets/img/banner_manutencao.jpg';

import Beneficios from './Beneficios';
import Promocoes from './Promocoes'
import AboutUs from '../Componentes/AboutUs'
import TempoCompartilhado from '../Componentes/TempoCompartilhado'
import Rci from '../Componentes/Rci'
import Depoimentos from '../Componentes/Depoimentos'
import Contato from '../Componentes/SectionForm'
import Instagram from '../Componentes/Instagram'
import CarouselNovo from '../Componentes/CarouselNovo'

const underConstructionStyle = {
  position: 'fixed',
  top: 0,
  left: 0,
}

class Site extends Component {
  render(){
    return (
      <div>
      {/* <div className="App bgApp oXBlock"> */}
        {/* <Navbar/>
        <Carousel/>
        <AboutUs></AboutUs>
        <Beneficios/>
        <TempoCompartilhado/>
        <Rci></Rci>
        <Promocoes></Promocoes>
        <Depoimentos></Depoimentos>
        <Instagram></Instagram>
        <Contato></Contato>
        <FooterSite/> */}
        <img style={underConstructionStyle} src={underConstruction} alt="Website under construction" />
      </div>
    );
  }
}
export default Site;
