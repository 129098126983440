import React, { Component } from "react";
import {api} from '../../services/api';
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";

import Dialog from '@material-ui/core/Dialog';
import DialogAction from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import TimerIcon from '@material-ui/icons/Timer';
import logoLogin from '../../imagens/lop.png';

import Estatistica from './Estatistica'

import { connect } from 'react-redux';
import { Link } from "react-router-dom";
//import ClipLoader from "react-spinners/ClipLoader";
const override = css`
  display: block;
  align: center;
  border-color: orange;
  position: absolute;
  z-index: 99;
  top:50%;
  left:40%;
`;

var timer_obj;

const Snack = {
  message: '',
  color: '',
  icon: null,
  
}

class administracao extends Component {
  constructor(props){
    super(props);
      this.state = this.props.dataFromParent;
    }
    Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    loadingSnackBar = (snack) =>  { //Função para carregar a snack bar 
    this.setState({
        snackBarColor: snack.color,
        snackBarMessage: snack.message,
        snackbarIcon: snack.icon,
        snackBarOpen: true
    });
    setTimeout(
        function () {
            this.setState({ snackBarOpen: false });
        }.bind(this),
        5500
    );
    }
    expandedControl = panel => (event, isExpanded) => {
      var t = (isExpanded ? panel : false);
      this.setState({expanded: t})
    };
    componentWillUnmount()
    {
      if(this.state.loading == true)
      {
        this.setState({loading:false})
        clearTimeout(timer_obj);
      }
    }

    handleChange = event =>{
      switch(event.target.id)
      {
        case 'email':
        this.setState(
          {
            login : event.target.value
          }
        )
        break;
        case 'senha':
        this.setState(
          {
            password : event.target.value
          }
        )
        break;
        default:
          break;
      }
    }
        
    validarLogin = event =>{

      const logar = {
        login: this.state.login,
        password: this.state.password
      }

      api.post('/login',logar)
      .then(response => {
            this.setState({
              loading:false,
              access_token: response.data.token,
              email: response.data.login,
            })  
          this.setState({tela:2})
          this.props.scrollToTop();
          this.props.parentCallback(this.state);  
      })
      .catch(error => {  
        console.log("ERRO:",error)
        Snack.message ='E-mail ou senha inválidos';
            Snack.color = "warning"
            Snack.icon = ErrorIcon
            this.loadingSnackBar(Snack)
      })
    }

  render() {
    return (
      <div>
        <div className="sweet-loading">
          <ClipLoader
            css={override}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={this.state.loading}
          />
        </div> 
        <Snackbar
          place="bc"
          open={this.state.snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
          >
          <MuiAlert elevation={6} variant="filled" severity={this.state.snackBarColor}>
            {this.state.snackBarMessage}
          </MuiAlert>
        </Snackbar>  
        <h1>Área Administrativa</h1>
            <h4>Essa é uma área administrativa do Tauá Vip Club, se você for um administrador, prossiga fazendo o login</h4>
          <br/>
        <button className="botaoCalc " variant="contained" onClick={() => { this.setState({open : true,})}}>Login Administrador</button>
        <br/><br/><br/>
        
        <Dialog
          open={this.state.open}
          onClose={() => this.state.close}
          fullWidth='true'
        
          aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title" style={{background:' #441c5c'}}>  
                <div className="loginImg">
                    <img src={logoLogin} id="imgLogin"className="img-fluid" />
                </div> 
                <div className="form col" style={{width:'100%', justifyContent: 'space-between',}}>
                    <h4 className="login_style" >E-mail:</h4>
                    <input id="email" className="form-control" type="text" onChange={this.handleChange}/>            
                    <h4 className="login_style">Senha:</h4>
                    <input id="senha" className="form-control" type="password" onChange={this.handleChange}/> 
                </div>
                </DialogTitle>
              <DialogAction className="DialogCalc" style={{background:' #441c5c'}}>
                <div className="botoes" row>
                  <button className="botaoCalc " variant="contained" style={{background:'white',color:'#441c5c'}} onClick={() => { this.setState({open : false,})}} >Voltar</button>
                  <div style={{padding:10}}></div>
                  <button className="botaoCalc " variant="contained" style={{background:'white',color:'#441c5c'}} onClick={this.validarLogin}> Logar </button>
                </div>
              </DialogAction>
          </Dialog>
          
      </div>      

    );
  }
}
const mapStateToProps = ({ login }) => {
  const { user } = login;

  return {
    user
  };
};

export default connect (mapStateToProps, {
  
}) (administracao);
