import {
    LOGIN_USER,
    LOGIN_USER_FAIL,
    LOGIN_USER_SUCCESS
} from './types'
import { api } from '../services/api';

import axios from 'axios';

export const onLoginPress = (res) => {
    return ( dispatch ) => {
        dispatch({ 
            type: LOGIN_USER_SUCCESS,
            payload: res
        })
    }
}




