import React, { Component } from "react";

import Reserv from "./Reserv"
import Calculadora from "./Calculadora"
import { ViewEncapsulation } from '@angular/core';

import { createStore } from 'redux'
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'


export default class reserva extends Component {

    constructor(props){
        super(props);
        this.state = {
            value:"",
            dataChegada:'',
            dataSaida:  '',
            pax : '1',
            chd : '0',
            CHDFree: '0',
            cpf:'',
            idContrato: "",
            codigoEspaco:'',
            qtde:'',
            alimentacao :[],
            hoteis:[],
            hotel:'',
            pontos:'',
            tela: 1,
            descricaoEspaco:'',
            QtdeMaxPAX:'',
            QtdeMaxCHD:'',
            IDEspaco: '',
            quartos:[],
           
            open: false,
            tipoEspacos: [],

            focusedInput: null,
            contratos:[],
            quartos: [],
            loading: false,
            expanded: false,
 
            precoTotal : '0', 
            bandeiras:[],
            nomeCartao:'',
            bandeiraCartao:'00001',
            cartaoNumero:'',
            validadeCartao:'',
            cvv:'',
            parcelas:'1',
            cpfCartao:'',
            observacao: 'Sem observações', 
            clientes: null,
            codigoPacote: 'PC001',
            sucesso: '',

            snackBarColor: '',
            snackBarMessage: '',
            snackbarIcon: null,
            snackBarOpen: false,
        }
    }

    componentDidMount() {

        Events.scrollEvent.register('begin', function () {
          console.log("begin", arguments);
        });
    
        Events.scrollEvent.register('end', function () {
          console.log("end", arguments);
        });
    
      }
      scrollToTop() {
        scroll.scrollToTop();
      }

    callbackFunction = (childData) => {
        this.setState(childData)
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
      }

    Escolha = () => {
        if(this.state.tela===2)
        {
            return(
                <Reserv 
                    dataFromParent = {this.state}
                    parentCallback = {this.callbackFunction}
                    scrollToTop = {this.scrollToTop}
                />            
            )
        }
        return(
            <Calculadora 
                dataFromParent = {this.state}
                parentCallback = {this.callbackFunction}
                scrollToTop = {this.scrollToTop}
            />
        )
    }

    render() {
        return (
            <div>
                <this.Escolha></this.Escolha>
            </div>
        )
    }
}
