import React, { Component } from "react";
import {api} from '../../services/api';
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import Divider from '@material-ui/core/Divider';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import {hist} from '../../index';

import {cpfMask} from '../mask/index';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import Dialog from '@material-ui/core/Dialog';
import DialogAction from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Snackbar, Button} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import TimerIcon from '@material-ui/icons/Timer';
import logoLogin from '../../imagens/lop.png';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {DateRangeInput} from '@datepicker-react/styled';
import {pt} from 'date-fns/esm/locale'
import { connect } from 'react-redux';
import moment from "moment";
import Paper from '@material-ui/core/Paper';
import {
  Chart,
  BarSeries,
  Title,
  ArgumentAxis,
  ValueAxis,
} from '@devexpress/dx-react-chart-material-ui';

import { Animation } from '@devexpress/dx-react-chart';
import fileSaver from 'file-saver'

//import ClipLoader from "react-spinners/ClipLoader";
const override = css`
  display: block;
  align: center;
  border-color: orange;
  position: absolute;
  z-index: 99;
  top:50%;
  left:40%;
`;

var timer_obj;

const Snack = {
  message: '',
  color: '',
  icon: null,
  
}

class Estatistica extends Component {
  constructor(props){
    super(props);
        this.state = this.props.dataFromParent;
    }
    Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    loadingSnackBar = (snack) =>  { //Função para carregar a snack bar 
    this.setState({
        snackBarColor: snack.color,
        snackBarMessage: snack.message,
        snackbarIcon: snack.icon,
        snackBarOpen: true
    });
    setTimeout(
        function () {
            this.setState({ snackBarOpen: false });
        }.bind(this),
        5500
    );
    }
    expandedControl = panel => (event, isExpanded) => {
      var t = (isExpanded ? panel : false);
      this.setState({expanded: t})
    };
    componentWillUnmount()
    {
      if(this.state.loading == true)
      {
        this.setState({loading:false})
        clearTimeout(timer_obj);
      }
    }

    componentDidMount()
    {
      if((this.props.user === null) || (typeof(this.props.user) === 'undefined'))
      {
        hist.push("/home")
        // voltar para a tela inicial
      }
      else
      {
        setTimeout(() => {
          this. getReservas()
        },100)
      }
    }
    
    exportXls = () => {
        return dispatch => {     
            api.get("/moduloReserva/csv?start=" + this.state.dataChegada + "&end="+this.state.dataSaida, 
            {headers: { Authorization: this.state.access_token}}, { responseType: 'arraybuffer' })
            .then( res => {  
                var blob = new Blob([res.data], {headers: { Connection: 'keep-alive'}},{type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'}, 
                );
                fileSaver.saveAs(blob, 'cotacao.xlsx');     
            })
            .catch(err => {
                alert('Erro ao obter o arquivo')
            })
        }
    }

    exportXlsReservas = () => {
        return dispatch => {
            console.log('chegada:', this.state.dataChegada)
            api.get("/moduloReserva/csv?start=" + this.state.dataChegada + "&end="+this.state.dataSaida,{
                headers: { Authorization: this.state.access_token}}, 
                {responseType : "arraybuffer"}, 
                {'Content-type':'application/x-www-form-urlencoded'}, 
                {'Accept-Encoding':'gzip, deflate, br'}, 
                {Connection: 'keep-alive'}
            )
            .then( res => {  
                let fileDownload = require('js-file-download');
                fileDownload(res.data, "reservas.xlsx" )
            })
            .catch(err => {
                alert('Erro ao obter o arquivo')
            })
        }
    }

    getReservas = event => {
        this.setState({loading:true})
    
          timer_obj = setTimeout(() => {
            if(this.state.loading == true)
            {
              this.setState({loading:false})
              Snack.message ="Falha na comunicação com o servidor"
              Snack.color = "warning"
              Snack.icon = ErrorIcon
              this.loadingSnackBar(Snack)
            }
          }, 20000)

        api.get('/moduloDigital',{ headers: { Authorization: this.state.access_token}})
          .then(response => {
            this.setState({ 
                reservas:response.data,
                tipoQuarto:response.data.tipoEspacos              
            })
            this.setState({loading:false})
          })
        api.get('/moduloReserva',{ headers: { Authorization: this.state.access_token}})
        .then(response => {
            this.setState({ 
                modulos:response.data,            
            })
            console.log('moduloReserva:', this.state.modulos)
            this.setState({loading:false})
          })
          .catch(error => 
          {
            console.log("ERRORconsole:",error)
            Snack.message =({error}.error.response);
            Snack.color = "warning"
            Snack.icon = ErrorIcon
            this.loadingSnackBar(Snack)
            this.setState({loading:false})
            clearTimeout(timer_obj);
            
          })  
          console.log('entrada:', this.state.dataChegada)
          console.log('saida:', this.state.dataSaida)
        }

        handleChange = event =>
        {

            switch (event.target.id) {
                case 'hotel': 
                this.setState(
                {
                    hotel : event.target.value 
                } 
                )
                break;
            case 'dataSaida': 
                this.setState(
                {
                    dataSaida : event.target.value
                }
                )
                break;
            case 'dataChegada': 
                this.setState(
                {
                    dataChegada : event.target.value
                }
                )
                break;
            default:
                break;
            }
        }

  render() {    
    return (
      <div>
          { 
            this.state.cont=0,
            this.state.contSuppis=0,
            this.state.contTrip=0,
            this.state.contSut=0,
            this.state.contLuxo=0,
            this.state.contMaster=0,
            this.state.contMasterRN=0,
            this.state.contPreci=0,
            this.state.contPresiRn=0,
            this.state.contStdCasal=0,
            this.state.contStdPlCasal=0,
            this.state.contStdlExec=0,
            this.state.contSuper=0,
            this.state.contSuperExec=0,
            this.state.contSupeRn=0,
            this.state.contSupRexv=0,
            this.state.contEspqcial=0,
            this.state.contGovernamental=0,
            this.state.contL2=0,    
            this.state.contL3=0,       
            this.state.contL4=0,        
            this.state.contNobre=0,          
            this.state.contPrecidenci=0,          
            this.state.contSolarium=0,          
            this.state.contStandard=0,           
            this.state.contSuperiorDEF=0,         
            this.state.contSuperior=0,          
            this.state.contSuperiorKG=0,          
            this.state.contSuperSol=0,

            this.state.reservas.map(
                                  (reserva, _id) => {
                                      this.state.tipoQuarto = reserva.tipoEspaco
                                      console.log("tipoQuarto:", this.state.tipoQuarto)
                                      if(this.state.tipoQuarto[0] == "SUP") 
                                      {
                                         this.state.cont=this.state.cont+1
                                         console.log("cont:", this.state.cont)  
                                      }
                                      if(this.state.tipoQuarto[0] == "SUP PIS") 
                                      {
                                         this.state.contSuppis=this.state.contSuppis+1
                                         console.log("contSuppis:", this.state.contSuppis)  
                                      }
                                      if(this.state.tipoQuarto[0] == "SUP TRIPLO") 
                                      {
                                         this.state.contTrip=this.state.contTrip+1
                                         console.log("contTrip:", this.state.contTrip)  
                                      }
                                      if(this.state.tipoQuarto[0] == "SUT") 
                                      {
                                         this.state.contSut=this.state.contSut+1
                                         console.log("contSut:", this.state.contSut)  
                                      }

                                      if(this.state.tipoQuarto[0] == "LUXOCASAL") 
                                      {
                                         this.state.contLuxo=this.state.contLuxo+1
                                         console.log("contLuxo:", this.state.contLuxo)  
                                      }
                                      if(this.state.tipoQuarto[0] == "MASTER") 
                                      {
                                         this.state.contMaster=this.state.contMaster+1
                                         console.log("contMaster:", this.state.contMaster)  
                                      }
                                      if(this.state.tipoQuarto[0] == "MASTERRN") 
                                      {
                                         this.state.contMasterRN=this.state.contMasterRN+1
                                         console.log("contMasterRN:", this.state.contMasterRN)  
                                      }
                                      if(this.state.tipoQuarto[0] == "PRESIDENCI") 
                                      {
                                         this.state.contPreci=this.state.contPreci+1
                                         console.log("contPreci:", this.state.contPreci)  
                                      }
                                      if(this.state.tipoQuarto[0] == "PRESIDENRN") 
                                      {
                                         this.state.contPresiRn=this.state.contPresiRn+1
                                         console.log("contPresiRn:", this.state.contPresiRn)  
                                      }
                                      if(this.state.tipoQuarto[0] == "STDCASAL") 
                                      {
                                         this.state.contStdCasal=this.state.contStdCasal+1
                                         console.log("contStdCasal:", this.state.contStdCasal)  
                                      }
                                      if(this.state.tipoQuarto[0] == "STDPLCASAL") 
                                      {
                                         this.state.contStdPlCasal=this.state.contStdPlCasal+1
                                         console.log("contStdPlCasal:", this.state.contStdPlCasal)  
                                      }
                                      if(this.state.tipoQuarto[0] == "STDPLEXEC") 
                                      {
                                         this.state.contStdlExec=this.state.contStdlExec+1
                                         console.log("contStdlExec:", this.state.contStdlExec)  
                                      }
                                      if(this.state.tipoQuarto[0] == "SUPERBANHE") 
                                      {
                                         this.state.contSuper=this.state.contSuper+1
                                         console.log("contSuper:", this.state.contSuper)  
                                      }
                                      if(this.state.tipoQuarto[0] == "SUPERIOREX") 
                                      {
                                         this.state.contSuperExec=this.state.contSuperExec+1
                                         console.log("contSuperExec:", this.state.contSuperExec)  
                                      }
                                      if(this.state.tipoQuarto[0] == "SUPERIORRN") 
                                      {
                                         this.state.contSupeRn=this.state.contSupeRn+1
                                         console.log("contSupeRn:", this.state.contSupeRn)  
                                      }
                                      if(this.state.tipoQuarto[0] == "SUPERIREXV") 
                                      {
                                         this.state.contSupRexv=this.state.contSupRexv+1
                                         console.log("contSupRexv:", this.state.contSupRexv)  
                                      }
                                      if(this.state.tipoQuarto[0] == "ESPECIAL") 
                                      {
                                         this.state.contEspqcial=this.state.contEspqcial+1
                                         console.log("contEspqcial:", this.state.contEspqcial)  
                                      }
                                      if(this.state.tipoQuarto[0] == "GOVERNAMEN") 
                                      {
                                         this.state.contGovernamental=this.state.contGovernamental+1
                                         console.log("contGovernamental:", this.state.contGovernamental)  
                                      }
                                      if(this.state.tipoQuarto[0] == "LUXO L2") 
                                      {
                                         this.state.contL2=this.state.contL2+1
                                         console.log("contL2:", this.state.contL2)  
                                      }
                                      if(this.state.tipoQuarto[0] == "LUXO L3") 
                                      {
                                         this.state.contL3=this.state.contL3+1
                                         console.log("contL3:", this.state.contL3)  
                                      }
                                      if(this.state.tipoQuarto[0] == "LUXO L4") 
                                      {
                                         this.state.contL4=this.state.contL4+1
                                         console.log("contL4:", this.state.contL4)  
                                      }
                                      if(this.state.tipoQuarto[0] == "NOBRE") 
                                      {
                                         this.state.contNobre=this.state.contNobre+1
                                         console.log("contNobre:", this.state.contNobre)  
                                      }
                                      if(this.state.tipoQuarto[0] == "PRESIDENCI") 
                                      {
                                         this.state.contPrecidenci=this.state.contPrecidenci+1
                                         console.log("contPrecidenci:", this.state.contPrecidenci)  
                                      }
                                      if(this.state.tipoQuarto[0] == "SOLARIUM") 
                                      {
                                         this.state.contSolarium=this.state.contSolarium+1
                                         console.log("contSolarium:", this.state.contSolarium)  
                                      }
                                      if(this.state.tipoQuarto[0] == "STANDARD") 
                                      {
                                         this.state.contStandard=this.state.contStandard+1
                                         console.log("contStandard:", this.state.contStandard)  
                                      }
                                      if(this.state.tipoQuarto[0] == "SUPERIODEF") 
                                      {
                                         this.state.contSuperiorDEF=this.state.contSuperiorDEF+1
                                         console.log("contSuperiorDEF:", this.state.contSuperiorDEF)  
                                      }
                                      if(this.state.tipoQuarto[0] == "SUPERIOR") 
                                      {
                                         this.state.contSuperior=this.state.contSuperior+1
                                         console.log("contSuperior:", this.state.contSuperior)  
                                      }
                                      if(this.state.tipoQuarto[0] == "SUPERIORKG") 
                                      {
                                         this.state.contSuperiorKG=this.state.contSuperiorKG+1
                                         console.log("contSuperiorKG:", this.state.contSuperiorKG)  
                                      }
                                      if(this.state.tipoQuarto[0] == "SUPERSOLTE") 
                                      {
                                         this.state.contSuperSol=this.state.contSuperSol+1
                                         console.log("contSuperSol:", this.state.contSuperSol)  
                                      }
                                  }
                              )
                            }
        <div className="sweet-loading">
          <ClipLoader
            css={override}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={this.state.loading}
          />
        </div> 
        <Snackbar
          place="bc"
          open={this.state.snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
          >
          <MuiAlert elevation={6} variant="filled" severity={this.state.snackBarColor}>
            {this.state.snackBarMessage}
          </MuiAlert>
        </Snackbar>  
        <div>
           
  
            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography>Reservas</Typography>            
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography>
                        <div>
                            <Snackbar
                                place="bc"Codigo
                                open={this.state.snackBarOpen}Codigo
                                closeNotification={() => this.setState({ snackBarOpen: false })}
                                close
                                >
                                <MuiAlert elevation={6} variant="filled" severity={this.state.snackBarColor}>
                                {this.state.snackBarMessage}
                                </MuiAlert>

                            </Snackbar>

                            <div className="form-group" > 
                            <h3> Reservas</h3>
                            <br></br>
                            <Table
                                aria-label="a dense table" 
                                >
                            <TableHead>
                                <TableRow> 
                                    <TableCell> Sucesso</TableCell>
                                    <TableCell> Contrato id </TableCell>
                                    <TableCell> Data Chegada </TableCell>
                                    <TableCell> Data Saída </TableCell>
                                    <TableCell> Nome </TableCell>
                                    <TableCell> Telefone </TableCell>
                                    <TableCell> E-mail </TableCell>
                                    <TableCell> CPF </TableCell>   
                                </TableRow>
                            </TableHead>
                                
                                <TableBody>
                                    {
                                    this.state.modulos ? this.state.modulos.map(
                                        (modulo, _id) => {
                                            
                                        return(
                                            <TableRow key={modulo._id}>
                                                <TableCell> {modulo.success ? "Sim":"Não"}</TableCell>             
                                                <TableCell> {modulo.contratoId}</TableCell>
                                                <TableCell> {modulo.dataChegada}</TableCell>
                                                <TableCell> {modulo.dataSaida}</TableCell>
                                                <TableCell> {modulo.cliente[0].nome}</TableCell>
                                                <TableCell> {modulo.cliente[0].telefone}</TableCell>
                                                <TableCell> {modulo.cliente[0].email}</TableCell>
                                                <TableCell> {modulo.cliente[0].cpf}</TableCell>
                                            </TableRow>
                                        )
                                        }
                                    ):null
                                    }
                                </TableBody>
                                </Table>
                            </div>
                                <div className="sweet-loading">
                                <ClipLoader
                                css={override}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                                />
                                </div>
                        </div>
                    </Typography>
                </ExpansionPanelDetails>
        </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                <Typography>Tabela Cotação</Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                    <Typography>
                        <div>
                            <Snackbar
                                place="bc"Codigo
                                open={this.state.snackBarOpen}Codigo
                                closeNotification={() => this.setState({ snackBarOpen: false })}
                                close
                                >
                                <MuiAlert elevation={6} variant="filled" severity={this.state.snackBarColor}>
                                {this.state.snackBarMessage}
                                </MuiAlert>

                            </Snackbar>

                            <div className="form-group" > 
                            <h3>Cotações </h3>
                            <br></br>
                            <Table
                                aria-label="a dense table" 
                                >
                            <TableHead>
                                <TableRow>
                                    
                                    <TableCell> CPF</TableCell>
                                    <TableCell> Contrato id </TableCell>
                                    <TableCell> Apartamento</TableCell>
                                    <TableCell> Data Chegada </TableCell>
                                    <TableCell> Data Saída </TableCell>
                                    <TableCell> Código Pacote </TableCell>
                                    <TableCell> Telefone </TableCell>
                                    <TableCell> E-mail </TableCell>
                                    <TableCell> Nome </TableCell>
                                </TableRow>
                            </TableHead>
                                
                                <TableBody>
                                    {
                                    this.state.reservas.map(
                                        (reserva, _id) => {
                                            
                                        return(
                                            <TableRow key={reserva._id}>
                                                <TableCell> {reserva.cpf}</TableCell>             
                                                <TableCell> {reserva.contratoId}</TableCell>
                                                <TableCell> {reserva.tipoEspaco}</TableCell>
                                                <TableCell> {reserva.dataChegada}</TableCell>
                                                <TableCell> {reserva.dataSaida}</TableCell>
                                                <TableCell> {reserva.codigoPacote}</TableCell>
                                                <TableCell> {reserva.telefone}</TableCell>
                                                <TableCell> {reserva.email}</TableCell>
                                                <TableCell> {reserva.nome}</TableCell>
                                            </TableRow>
                                        )
                                        }
                                    )
                                    }
                                </TableBody>
                                </Table>
                            </div>
                                <div className="sweet-loading">
                                <ClipLoader
                                css={override}
                                size={150}
                                color={"#123abc"}
                                loading={this.state.loading}
                                />
                                </div>
                        </div>
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <ExpansionPanel>
                <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
                >
                    <Typography>Gráfico Quartos </Typography>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails>
                
                    <Typography>
                        <div className="row">     
                            <div className="col-12" style={{marginBottom:"20px"}}>
                                    <Paper>
                                        <Chart
                                        data={[
                                            {Quarto:'Especial', Quantidade:this.state.contEspqcial},
                                            {Quarto:'Governament', Quantidade:this.state.contGovernamental},
                                            {Quarto:'Luxo L2', Quantidade:this.state.contL2},
                                            {Quarto:'Luxo L3', Quantidade:this.state.contL3},
                                            {Quarto:'Luxo L4', Quantidade:this.state.contL4},
                                            {Quarto:'Nobre', Quantidade:this.state.contNobre},
                                            {Quarto:'Precidenci', Quantidade:this.state.contPreci},
                                            {Quarto:'Solarium', Quantidade:this.state.contSolarium},
                                            {Quarto:'Standard', Quantidade:this.state.contStandard},
                                            {Quarto:'SuperiorDEF', Quantidade:this.state.contSuperiorDEF},
                                            {Quarto:'Superior', Quantidade:this.state.contSuperior},
                                            {Quarto:'SuperiorKG', Quantidade:this.state.contSuperSol},
                                            {Quarto:'SuperSolte', Quantidade:this.state.contSuperSol},  
                                        ]}
                                        >
                                            <ArgumentAxis />
                                            <ValueAxis max={12} />

                                            <BarSeries
                                                valueField="Quantidade"
                                                argumentField="Quarto"
                                            />
                                            <Title text="Taua Grande Hotel de Araxa" />
                                            <Animation />
                                        </Chart>
                                    </Paper>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                            <div className="col-12" style={{marginBottom:"20px"}}>
                                    <Paper>
                                        <Chart
                                        data={[
                                            {Quarto:'LuxoCasal', Quantidade:this.state.contLuxo},
                                            {Quarto:'Master', Quantidade:this.state.contMaster},
                                            {Quarto:'MasterRN', Quantidade:this.state.contMasterRN},
                                            {Quarto:'Presidenci', Quantidade:this.state.contPreci},
                                            {Quarto:'PresidenRN', Quantidade:this.state.contPresiRn},
                                            {Quarto:'StdCasal', Quantidade:this.state.contStdCasal},
                                            {Quarto:'StdPlCasal', Quantidade:this.state.contStdPlCasal},
                                            {Quarto:'StdPlExec', Quantidade:this.state.contStdlExec},
                                            {Quarto:'SuperBanhe', Quantidade:this.state.contSuper},
                                            {Quarto:'SuperiorEx', Quantidade:this.state.contSuperExec},
                                            {Quarto:'SuperiorRN', Quantidade:this.state.contSupeRn},
                                            {Quarto:'SuperiRexv', Quantidade:this.state.contSupRexv},  
                                        ]}
                                        >
                                            <ArgumentAxis />
                                            <ValueAxis max={12} />

                                            <BarSeries
                                                valueField="Quantidade"
                                                argumentField="Quarto"
                                            />
                                            <Title text="Hotel Taua Caete" />
                                            <Animation />
                                        </Chart>
                                    </Paper>
                            </div>
                            <br/>
                            <br/>
                            <br/>
                             
                            <div className="col-12" >  
                            
                                    <Paper>       
                                        <Chart
                                        data={[
                                            {Quarto:'SUP', Quantidade:this.state.cont},
                                            {Quarto:'SUP PIS', Quantidade:this.state.contSuppis},
                                            {Quarto:'SUP TRIPLO', Quantidade:this.state.contTrip},
                                            {Quarto:'SUT', Quantidade:this.state.contSut},
                                        ]}
                                        >
                                            <ArgumentAxis />
                                            <ValueAxis max={12} />

                                            <BarSeries
                                                valueField="Quantidade"
                                                argumentField="Quarto"
                                            />
                                            <Title text="Hotel Taua Atibaia" />
                                            <Animation />
                                        </Chart>
                                    </Paper>
                            </div>
                        </div>
                    </Typography>
                </ExpansionPanelDetails>
            </ExpansionPanel>

            <button className="botaoCalc " variant="contained" 
                          onClick={() => {           
                            this.setState({tela:1})
                            this.setState({open : false,})
                            this.props.parentCallback(this.state);
                          }} 
                         >Voltar</button>
            
            
        </div>      
      </div>      

    );
  }
}
const mapStateToProps = ({ login }) => {
  const { user } = login;

  return {
    user
  };
};

export default connect (mapStateToProps, {
  
}) (Estatistica);