import React from 'react'
import '../Styles/Instagram.css'
import InstaGrid from './InstaGrid'
export default class Instagram extends React.Component{
    render()
    {
        return(
            <div className="container-fluid relativeGrid " id="instagram">
            
            
                <div className="row  marginTexto no-gutters mb-3 marginBottomMedium marginTopMin pd">
                    <div className="offset-xl-1 col-xl-11 static">
                    <p>
                        <h3 className="tituloEspecial">Sigá-nos no <spam className="spamEspecial"> <a href="https://www.instagram.com/tauaresorts/" target="_blank"className="linkInsta"><br/>Instagram</a></spam><br/>E conheça tudo que rola no Tauá VIP Club </h3>
                    </p>
                    </div>
                </div>
                <div className="static">
                        <InstaGrid account="tauaresorts" numberOfMediaElements={4}></InstaGrid>
                </div>
                {/*
                <div className="row">
                    <div className="col-xl-3 col-12 col-md-6">
                        <div className="imagemInsta bg-primary" data-aos="flip-up"> 
            
                        </div>
                    </div>
                    <div className="col-xl-3 col-12 col-md-6">
                        <div className="imagemInsta bg-primary"  data-aos="flip-up"> 
            
                        </div>
                    </div>
                    <div className="col-xl-3 col-12 col-md-6">
                        <div className="imagemInsta bg-primary"  data-aos="flip-up"> 
            
                        </div>
                    </div>
                    <div className="col-xl-3 col-12 col-md-6">
                        <div className="imagemInsta bg-primary"  data-aos="flip-up"> 
            
                        </div>
                    </div>
                </div>
                */}
            </div>
        )
    }
}