import React , {Component}from 'react'
import img1 from '../../imagens/imgDep1.png'
import img2 from '../../imagens/imgDep2.png'
import '../Styles/SlideDepoimentos.css'
import img3 from '../../imagens/imgSlider3.png'
export default class SlidesDepoimentos extends Component{
    render()
    {
        return(
            <div id="carouselExampleControls" className="carousel slide mt-5" data-ride="carousel">
                <div classNameName="carousel-inner">
                <div className="carousel-item active">
                    <div className="row">
                        
                            
                            <div className="col-xl-6 col-12 objectCover">
                        

                            <img className="d-block roundedC w-100" id="imgDepo" src={img1} alt="First slide"/>
                    
            
                            </div>            
                            <div className="col-xl-6 col-12">
                                <p className="paragrafoDep">
                                “Gostamos muito do atendimento diferenciado, principalmente o horário de saída às 14:00 e os drinks de boas vindas.”
                                Danielle Fernandes – Hóspede Tauá VIP Clube
                                </p>
                            </div>
                    </div>

                    </div>
                <div className="carousel-item">
                    <div className="row">
                            <div className="col-xl-6 objectCover">
                            
                                                    
                            <img className="d-block roundedC w-100" id="imgDepo" src={img2} alt="Second Slide"/>
                            

                            </div>            
                            <div className="col-xl-6">
                                <p className="paragrafoDep">
                                    "Gosto muito dos hotéis da rede Tauá, e vejo muitas vantagens no plano.
                                    Ser VIP Club é ter benefícios e vantagens especiais, como por exemplo fazer um checkin diferenciado, com uma área exclusiva para associado, receber mimos quando se chega ao hotel, ter acesso a eventos exclusivos VIP Club, é poder pagar a alimentação com desconto especial, é poder sair um pouco mais tarde no dia do checkout 
                                    Enfim ....
                                    Ser VIP Club é ter um atendimento especial e diferenciado."
                                    Adriana Coelho                            
                                </p>
                            </div>
                    </div>
                </div>
               
                </div>
                <a className="mt-5 colorSeta" href="#carouselExampleControls" role="button" data-slide="prev">
                    <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                    <span className="sr-only">Previous</span>
                </a>

                <a className="mt-5 carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
                </a>
            </div>
        )
    }
}