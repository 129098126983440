import React, { Component } from "react";
import { Link, DirectLink, Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Administracao from "./Administracao"
import Estatistica from "./Estatistica"


export default class admin extends Component {

    constructor(props){
        super(props);
        this.state = {
            dataChegada:'',
            dataSaida:'',
            data:[],
            dataAtibaia:[],
            dataCaete:[],
            tela: 1,
            reservas:[],
            modulos:null,
            hotel:[],
            quarto:"",
            tipoQuarto:[],
            quantidade:[],

            cont:0,
            contSuppis:0,
            contTrip:0,
            contSut:0,

            contLuxo:0,
            contMaster:0,
            contMasterRN:0,
            contPreci:0,
            contPresiRn:0,
            contStdCasal:0,
            contStdPlCasal:0,
            contStdlExec:0,
            contSuper:0,
            contSuperExec:0,
            contSupeRn:0,
            contSupRexv:0,

            contEspqcial:0,
            contGovernamental:0,
            contL2:0,
            contL3:0,
            contL4:0,
            contNobre:0,
            contPrecidenci:0,
            contSolarium:0,
            contStandard:0,
            contSuperiorDEF:0,
            contSuperior:0,
            contSuperiorKG:0,
            contSuperSol:0,

            login:'',
            password:'',
            access_token : null,
            HotelNome : "",
            loading: false,
            open: true,
            snackBarColor: '',
            snackBarMessage: '',
            snackbarIcon: null,
            snackBarOpen: false,
        }
    }
    componentDidMount() {

        Events.scrollEvent.register('begin', function () {
          console.log("begin", arguments);
        });
    
        Events.scrollEvent.register('end', function () {
          console.log("end", arguments);
        });
    
      }
      scrollToTop() {
        scroll.scrollToTop();
      }

    callbackFunction = (childData) => {
        this.setState(childData)
    }

    componentWillUnmount() {
        Events.scrollEvent.remove('begin');
        Events.scrollEvent.remove('end');
      }

    Escolha = () => {
        if(this.state.tela===2)
        {
            return(
                <Estatistica
                dataFromParent = {this.state}
                parentCallback = {this.callbackFunction}
                scrollToTop = {this.scrollToTop}
                />            
            )
        }
        return(
            <Administracao
            dataFromParent = {this.state}
            parentCallback = {this.callbackFunction}
            scrollToTop = {this.scrollToTop}
            />
        )
    }

    render() {
        return (
            <div>
                <this.Escolha></this.Escolha>
            </div>
        )
    }
}
