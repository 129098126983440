import React, { Component } from "react";
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import { makeStyles } from "@material-ui/core/styles";
import moment from 'moment'
import styles from "assets/jss/material-dashboard-pro-react/views/chartsStyle.js";
import {api} from '../../services/api';
import Calendar from 'react-calendar';
import { clearConfigCache } from "prettier";

import {format} from 'date-fns/esm'
import {pt} from 'date-fns/esm/locale'

import { ViewEncapsulation } from '@angular/core';

import TagsInput from "react-tagsinput";
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogAction from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
//import { Alert } from "reactstrap";

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Divider from '@material-ui/core/Divider';
import DeleteIcon from '@material-ui/icons/Delete';

import './Reserv.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useHistory
} from "react-router-dom";

import {hist} from '../../index'

import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import {DateRangeInput} from '@datepicker-react/styled';
//import {DateRangeInput} from '../../components/datepicker-react-PT-BR/styled';

import {cpfMask} from '../mask/index';
import { Provider } from 'react-redux';

import { connect } from 'react-redux';

import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import TimerIcon from '@material-ui/icons/Timer';
var timer_obj;

//import ClipLoader from "react-spinners/ClipLoader";
const override = css`
  display: block;
  align: center;
  border-color: orange;
  position: fixed;
  z-index: 99;
  top:50%;
  left:50%;
`;
const Snack = {
  message: '',
  color: '',
  icon: null
}

class Calculadora extends Component {
 /////////////////////////// Construtor e passagem das props para os states ///////
  constructor(props){

    super(props)
      this.state = this.props.dataFromParent;   
     
   }
Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

loadingSnackBar = (snack) =>  { //Função para carregar a snack bar
  this.setState({
      snackBarColor: snack.color,
      snackBarMessage: snack.message,
      snackbarIcon: snack.icon,
      snackBarOpen: true
  });
  setTimeout(
      function () {
          this.setState({ snackBarOpen: false });
      }.bind(this),
      5500
  );
}

componentWillUnmount()
    {
      if(this.state.loading == true)
      {
        this.setState({loading:false})
clearTimeout(timer_obj);
      }
    }

    componentDidMount()
    {
      if((this.props.user === null) || (typeof(this.props.user) === 'undefined'))
      {
        hist.push("/home")
        // voltar para a tela inicial
      }
      else
      {
        this.setState({cpf: this.props.user.cpf})
    
        setTimeout(() => {
          this.getContrato()
        },100)
      }
    }

//////////////////////////// Funções de action /////////////////////

  removePosition = event =>
  {
    let temp = [].concat(JSON.parse(JSON.stringify(this.state.tipoEspacos)));
    temp.splice(parseInt(event),1)
    this.setState({tipoEspacos: [].concat(temp)})
  }

  getBandeira = event => {
    this.setState({loading:true})

    timer_obj = setTimeout(() => {
      if(this.state.loading == true)
      {
        this.setState({loading:false})
        Snack.message ="Falha na comunicação com o servidor"
        Snack.color = "warning"
        Snack.icon = ErrorIcon
        this.loadingSnackBar(Snack)
      }
    }, 20000)
    api.get('/bandeira', { headers: { token: this.props.user.access_token, email: this.props.user.email}})           
      .then(response => {
        var lista = [];
        var base = {
          "Customer": {
              "PersonName": {
                  "GivenName": "",
                  "Surname": "",
                  "NameTitle": {}
              },
              "Telephone": 
              [{
                  "PhoneTechType": "1",
                  "AreaCityCode": "",
                  "PhoneNumber": ""
                },
              ],
              "Email": "",
              "Address": {
                  "AddressLine": "",
                  "StreetNmbr": "",
                  "District": "",
                  "CityName": "",
                  "CityCode": "",
                  "StateProv": "",
                  "PostalCode": "",
                  "CountryName": ""
              },
              "Document": {
                  "DocType": "CPF",
                  "DocID": "",
                  "BirthDate": "",
                  "Gender": "M"
              }
          },
          "ProfileType": "1",
          "ID": "0"
        }
        for(var cont = 0; cont < parseInt(this.state.pax)+parseInt(this.state.chd)+parseInt(this.state.CHDFree);cont++)
        {
          lista.push(base);
        }

        if(response.data.length < 1)
        {
          throw new Error('Tente novamente');
        }
        else
        {
          this.setState({
            clientes: [].concat(lista),
            bandeiras: response.data,
            loading:false,
            open : false,
          }) 
          clearTimeout(timer_obj);    
        }
        timer_obj = setTimeout(()=> {
          this.props.scrollToTop();
          this.setState({tela:2})
          this.props.parentCallback(this.state);
        },100)
      })      
      .catch(error => 
      {
        console.log("ERROR:",error)
        Snack.message = ({error}.error.response.data.message);
        Snack.color = "warning"
        Snack.icon = ErrorIcon
        this.loadingSnackBar(Snack)
        this.setState({loading:false})
        clearTimeout(timer_obj);
      })
  }

  getContrato = event => {
    this.setState({loading:true})
    api.get('/consultaContrato?cpf='+this.state.cpf.replace('.','').replace('.','').replace('-',''), { headers: { token: this.props.user.access_token, email: this.props.user.email}})
      .then(response => {
        this.setState({ 
          contratos: [].concat(response.data.Contrato),
        })
        this.setState({idContrato : this.state.contratos[0].ID})
        this.setState({hotel:this.state.contratos[0].HotelNome})
        console.log("contratos:", this.state.contratos)
        this.setState({loading:false})
        clearTimeout(timer_obj);
  
      })
      .catch(error => 
      {
        console.log("ERROR:",error)
        Snack.message =({error}.error.response.data.message);
        Snack.color = "warning"
        Snack.icon = ErrorIcon
        this.loadingSnackBar(Snack)
        this.setState({loading:false})
        clearTimeout(timer_obj);
        
      })
    }

    getEspaco = event => {
      console.log("idcontrato:", this.state.idContrato)
      this.state.contratos.map(
        (contrato )=>(               
            contrato.ID == this.state.idContrato ? this.setState({hotel:contrato.HotelNome}) : this.setState({hotel:null})
        )
      )
      api.get('/tipoEspaco?cpf='+this.state.cpf.replace('.','').replace('.','').replace('-','')+ '&contratoId='+this.state.idContrato, { headers: { token: this.props.user.access_token, email: this.props.user.email}})
      .then(response2 => {
        this.setState({ 
          quartos: response2.data.TiposEspaco,
          id: response2.data.ID  
        })
        this.setState({loading:false})
        clearTimeout(timer_obj);
      })
      .catch(error2 => 
      {
        console.log("ERROR2:",error2)
        if(typeof(error2) === 'undefined')
        {
          Snack.message =({error2}.error.response.data.message);
          Snack.color = "warning"
          Snack.icon = ErrorIcon
          this.loadingSnackBar(Snack)
          this.setState({loading:false})
          clearTimeout(timer_obj);
        }
      }) 
    }

    handleSubmit = event =>
    {
      if(this.state.tipoEspacos.length === 0)
      {
        Snack.message = "Por favor escolha um tipo de quarto para continuar"
        Snack.color = "warning"
        Snack.icon = ErrorIcon
        this.loadingSnackBar(Snack)
      }
      else 
      {
        const calcular = {
          dataChegada:this.state.dataChegada,
          dataSaida:this.state.dataSaida,
          cpf:this.state.cpf.replace('.','').replace('.','').replace('-',''),
          contratoId:this.state.idContrato,
          tipoEspacos:this.state.tipoEspacos
        };
        console.log("data:", this.state.dataChegada)
        this.setState({loading:true})
        timer_obj = setTimeout(() => 
        {
          if(this.state.loading === true)
          {
            clearTimeout(timer_obj);
            this.setState({loading:false})
            Snack.message = "Algo deu errado, tente novamente"
            Snack.color = "warning"
            Snack.icon = ErrorIcon
            this.loadingSnackBar(Snack)
          }
        }, 20000)
        api.post('/calculadora', calcular, { headers: { token: this.props.user.access_token, email: this.props.user.email}})
        .then(response => 
        {
          
          let temp = [].concat(JSON.parse(JSON.stringify(this.state.tipoEspacos)));
          let temp_new = [].concat(response.data.resultados.TipoEspaco).map( (value,i) => {return Object.assign(temp[i],value)})
          console.log("tamanho:", response.data.length)
          if(response.data.pacote){
            this.setState(
              {

                open : true,
                pontos : parseInt([].concat(response.data.resultados.TipoEspaco).reduce((total,numero) => total + parseInt(numero.QtdePontos.replace(".","").replace(",",".")),0)).toString(),
                Valor : parseFloat([].concat(response.data.resultados.TipoEspaco).reduce((total,numero) => total + parseFloat(numero.Valor.replace(".","").replace(",",".")),0)).toFixed(2).toString().replace(".",","),
                PeriodoTemporada : [].concat(response.data.resultados.TipoEspaco).map((value,i) => {return i === 0 ? value.PeriodoTemporada: null}),
                alimentacao :[].concat(response.data.pacote ),
                pax: [].concat(response.data.resultados.TipoEspaco).reduce((total,numero) => total + parseInt(numero.PAX),0),
                chd: [].concat(response.data.resultados.TipoEspaco).reduce((total,numero) => total + parseInt(numero.CHD),0),
                CHDFree: [].concat(this.state.tipoEspacos).reduce((total,numero) => total + parseInt(numero.chdFree),0),
                tipoEspacos: temp_new,
                loading:false,
                
              }) //QtdePontos
          
            clearTimeout(timer_obj);
            let total = 0;
            if(this.state.alimentacao != []){
              this.state.alimentacao.map( (ali, index) => 
              {
                total = total + parseFloat(ali.PrecoTotal.replace('.', '').replace(',', '.'))
              })
              this.setState(
              {
                precoTotal: total.toFixed(2).toString().replace('.', ',')
              })
            }
          
        }
        else{
          console.log("ERROR:")
            Snack.message ="Pacote de Alimentação não encontrado. Entre em contato com o e-mail relacionamentotvc1@taua.com.br ou pelo telefone (31) 3236 1920";
            Snack.color = "warning"
            Snack.icon = ErrorIcon
            this.loadingSnackBar(Snack)
            this.setState({loading:false})
            clearTimeout(timer_obj);
        }
        })
      
        .catch(error => 
        {
          console.log("ERROR:", error)
          Snack.message ={error}.error.response.data.message;
          Snack.color = "warning"
          Snack.icon = ErrorIcon
          this.loadingSnackBar(Snack)
          this.setState({loading:false})
          clearTimeout(timer_obj);
        })
      }
    } 
  handleChangeContrato(event) {
      this.setState({idContrato : event.target.value});
    }
  handleChange = event =>
  {
    let temp = JSON.parse(JSON.stringify(this.state.tipoEspacos));

    switch (event.target.id) {
      case 'hotel': 
      this.setState(
        {
            hotel : event.target.value
        }
      )
      break;
      case 'quantidade': 
        var obj = JSON.parse(event.target.value)
        
        var jaExiste = [].concat(temp.filter(posicao => posicao.codigo === obj["quarto"])).filter(posicao => posicao !== undefined)

        if(jaExiste.length < parseInt(obj["quantidade"]))
        {
          var novaFicha =[{qtde:'1',codigo : obj["quarto"], pax: '1', chd:'0', chdFree:'0', QtdeMaxPAX: obj["QtdeMaxPAX"] ,QtdeMaxCHD: obj["QtdeMaxCHD"]}]

          for(var i=0 ; i < parseInt(obj["quantidade"])-jaExiste.length ; i++)
          {
            temp = temp.concat(novaFicha)
          }

          this.setState({
            tipoEspacos: temp
          })
        }
        else
        {
          if(parseInt(obj["quantidade"]) === 0)
          {
            this.setState({
              tipoEspacos: temp.filter(posicao => posicao.codigo !== obj["quarto"])
            })
          }
          else
          {
            this.setState({
              tipoEspacos: temp.filter(posicao => posicao.codigo !== obj["quarto"]).concat(jaExiste.splice(jaExiste.length - parseInt(obj["quantidade"]),parseInt(obj["quantidade"])))
            })
          }
        }

        break;
      
      case 'contrato': 
        this.setState(
          {
            idContrato : event.target.value ,
          } 
        )
        break;
      case 'dataSaida': 
        this.setState(
          {
            dataSaida : event.target.value
          }
        )
        break;
      case 'dataChegada': 
        this.setState(
          {
            dataChegada : event.target.value
          }
        )
        break;
      
      case 'Adulto': 
        var obj = JSON.parse( event.target.value)

        temp[obj.i].pax = obj.quantidade

        this.setState(
          {
            tipoEspacos: temp
          }
        )
        break;

        case 'crianca' :
          var obj = JSON.parse( event.target.value)
          var chd_free=[];
          
          if(obj.quantidade > 2)
          {
            temp[obj.i].chdFree = "2"
            temp[obj.i].chd = (parseInt(obj.quantidade) - 2).toString();
          }
          else
          {
            temp[obj.i].chd = "0";
            temp[obj.i].chdFree = obj.quantidade;
          }
  
          this.setState(
            {
              tipoEspacos: temp,
            }
          )
          break;

      case 'obs' :
        this.setState(
          {
              obs : event.target.value
          }
        )
        break;

      case 'cpf': 
        this.setState({cpf:cpfMask(event.target.value)})
        break;

      case 'data': 
        console.log("Data:",event.target.value)
        break;
      default:
        break;
    }
    }
  
  ////////////////////////// Render principal da tela /////////////
  render() {
    const {cpf} = this.state
  return (
    <div>
      <Snackbar
        place="bc"Codigo
        open={this.state.snackBarOpen}Codigo
        closeNotification={() => this.setState({ snackBarOpen: false })}
        close
        >
        <MuiAlert elevation={6} variant="filled" severity={this.state.snackBarColor}>
          {this.state.snackBarMessage}
        </MuiAlert>

      </Snackbar>
      <div className="form-group row">
        <div className="form-group col-md-4">
            <label>CPF</label> 
            <input readOnly type="tel" maxLength='14' value={cpfMask(this.state.cpf)} id="cpf" className="form-control "></input>
            <div style={{padding:'1%'}}></div>
        </div>
        <div className="form-group col-md-3">
          <label htmlFor="inputEmail4">Contrato</label>
          <select id="contrato" onChange={this.handleChange} className="form-control"  >
            {this.state.contratos.map(
                (contrato )=>(               
                    <option value={contrato.ID}>{contrato.ID}</option> 
                )
              )
            }
          </select>
        </div>
        <div className="form-group col-md-5">
          <label htmlFor="inputCity">Hotel</label>
          <input readOnly type="text" className="form-control" id="hotel" value={this.state.hotel} />
        </div>
      </div>
      <div className="form-group row">      
    </div>
    <button className="botaoCalc " variant="contained" onClick={() => { this.getEspaco()}} >Ver Quartos</button>
    <div className="form-row" style={{marginLeft:1}}>
      <div className="form-group">
        <div className = "row" >
          
          <FormGroup>
            <label style={{marginLeft:15, width: '200px'}} id="chegada" htmlFor="inputState" >Data de entrada</label>
            <Input
              onChange={this.handleChange}
              type="date"
              name="date"
              id="dataChegada"
              placeholder="Data Chegada"
            />
          </FormGroup>
          
          <FormGroup>
          <label style={{marginLeft:30, width: '200px'}} id="saida" htmlFor="inputState">Data de saída</label>
            <Input
              onChange={this.handleChange}
              type="date"
              name="date"
              id="dataSaida"
              placeholder="Data Saída"
            />
          </FormGroup>
        </div>
        
      </div>
    </div>

    <br></br>
    <Divider variant="middle" />
    <br></br>

    <div className="form-group" > 
      <h3>1º Selecione o tipo do apartamento</h3>
      <br></br>
      <div className="form-group row">
        <label style={{width:'2%'}}></label>
        <label style={{width:'32%'}}> apartamento </label>
        <label style={{width:'33%'}}> Código </label>
        <label style={{width:'33%'}}> Quantidade </label>
      </div>
        <Table
          aria-label="a dense table" 
        >
          <TableBody>
            {
              this.state.quartos.map(
                quarto => {
                  return(
                  <TableRow key={quarto.ID}>
                    <TableCell style={{width:'33%', justifyContent: 'space-between'}} > {quarto.Descricao}</TableCell>
                    <TableCell style={{width:'33%', justifyContent: 'space-between'}}  id="espaco" onChange={this.handleChange}>{quarto.ID}</TableCell>             
                    <TableCell style={{width:'33%', justifyContent: 'space-between'}}  >
                      <select id="quantidade" value = {JSON.stringify({
                          "quarto": quarto["ID"],
                          "descricao": quarto["Descricao"],
                          "QtdeMaxPAX": quarto["QtdeMaxPAX"],
                          "QtdeMaxCHD": quarto["QtdeMaxCHD"],
                          "quantidade": [].concat(this.state.tipoEspacos.filter(posicao => posicao.codigo === quarto["ID"])).filter(posicao => posicao !== undefined).length
                        })} className="form-control"  onChange={this.handleChange}>
                        <option value = {JSON.stringify({
                          "quarto": quarto["ID"],
                          "descricao": quarto["Descricao"],
                          "QtdeMaxPAX": quarto["QtdeMaxPAX"],
                          "QtdeMaxCHD": quarto["QtdeMaxCHD"],
                          "quantidade": 0
                        })}>0</option>
                        <option value = {JSON.stringify({
                            "quarto": quarto["ID"],
                            "descricao": quarto["Descricao"],
                            "QtdeMaxPAX": quarto["QtdeMaxPAX"],
                            "QtdeMaxCHD": quarto["QtdeMaxCHD"],
                            "quantidade": 1
                        })}>1</option>
                        <option value = {JSON.stringify({
                            "quarto": quarto["ID"],
                            "descricao": quarto["Descricao"],
                            "QtdeMaxPAX": quarto["QtdeMaxPAX"],
                            "QtdeMaxCHD": quarto["QtdeMaxCHD"],
                            "quantidade": 2
                        })}>2</option>
                        <option value = {JSON.stringify({
                            "quarto": quarto["ID"],
                            "descricao": quarto["Descricao"],
                            "QtdeMaxPAX": quarto["QtdeMaxPAX"],
                            "QtdeMaxCHD": quarto["QtdeMaxCHD"],
                            "quantidade": 3
                        })}>3</option>
                        <option value = {JSON.stringify({
                            "quarto": quarto["ID"],
                            "descricao": quarto["Descricao"],
                            "QtdeMaxPAX": quarto["QtdeMaxPAX"],
                            "QtdeMaxCHD": quarto["QtdeMaxCHD"],
                            "quantidade": 4
                        })}>4</option>
                        <option value = {JSON.stringify({
                            "quarto": quarto["ID"],
                            "descricao": quarto["Descricao"],
                            "QtdeMaxPAX": quarto["QtdeMaxPAX"],
                            "QtdeMaxCHD": quarto["QtdeMaxCHD"],
                            "quantidade": 5
                        })}>5</option>
                        <option value = {JSON.stringify({
                            "quarto": quarto["ID"],
                            "descricao": quarto["Descricao"],
                            "QtdeMaxPAX": quarto["QtdeMaxPAX"],
                            "QtdeMaxCHD": quarto["QtdeMaxCHD"],
                            "quantidade": 6
                        })}>6</option>
                        </select>
                      </TableCell>
             </TableRow>
                )}
              )
            }
          </TableBody>
        </Table>
      </div>
      <br></br>
      <Divider variant="middle" />
      <br></br>
      <div className="form-group" > 
      <h3>2º Informe o número de hóspedes por apartamento</h3>
      <br></br>
      <div className="form-group row">
        <label style={{width:'1%'}}> </label>
        <label style={{width:'7%'}}>Item</label>
        <label style={{width:'15%'}}>Cod.</label>
        <label style={{width:'23%'}}>Nº Adultos</label>
        <label style={{width:'23%'}}>Nº Crianças <br></br>*(0 a 12 anos)</label>
        <label style={{width:'23%'}}>Total pessoas / Capacidade do apartamento</label>
        <label style={{width:'7%'}}>Excluir</label>
      </div>
      <Table
        aria-label="a dense table" 
      >
        <TableBody>
            {
              this.state.tipoEspacos.map(
                (quarto, i) => {
                  return(
                    <TableRow key={i}>

                          <TableCell style={{width:'7%'}}> {i+1}</TableCell>

                          <TableCell style={{width:'15%'}}>{quarto.codigo}</TableCell>

                          <TableCell style={{width:'23%'}}>
                            <div className="form-group row">
                              <select id="Adulto" className="form-control col-6"  onChange={this.handleChange}>
                                <option value = {JSON.stringify({
                                    "i": i,
                                    "quantidade": "1"
                                })}>1</option>
                                <option value = {JSON.stringify({
                                    "i": i,
                                    "quantidade": "2"
                                })}>2</option>
                                <option value = {JSON.stringify({
                                    "i": i,
                                    "quantidade": "3"
                                })}>3</option>
                                <option value = {JSON.stringify({
                                    "i": i,
                                    "quantidade": "4"
                                })}>4</option>
                                <option value = {JSON.stringify({
                                    "i": i,
                                    "quantidade": "5"
                                })}>5</option>
                                <option value = {JSON.stringify({
                                    "i": i,
                                    "quantidade": "6"
                                })}>6</option>
                                </select>
                                {/* <label style={{width:'2%'}}></label>
                                <label style={{marginTop:'5%'}} >{" / " + quarto.QtdeMaxPAX + " *"}</label>
                                <label style={{color:'#EB7228'}}>{parseInt(quarto.pax) > parseInt(quarto.QtdeMaxPAX) ? "Capacidade máxima exedida" : ""}</label> */}
                            </div>
                          </TableCell>

                          <TableCell style={{width:'23%'}}>
                            <div className="form-group row">
                              
                                <select  id="crianca" className="form-control col-6" onChange={this.handleChange} >
                                  <option value = {JSON.stringify({
                                    "i": i,
                                    "quantidade": "0"
                                  })}>0</option>
                                  <option value = {JSON.stringify({
                                      "i": i,
                                      "quantidade": "1"
                                  })}>1</option>
                                  <option value = {JSON.stringify({
                                      "i": i,
                                      "quantidade": "2"
                                  })}>2</option>
                                  <option value = {JSON.stringify({
                                      "i": i,
                                      "quantidade": "3"
                                  })}>3</option>
                                  <option value = {JSON.stringify({
                                      "i": i,
                                      "quantidade": "4"
                                  })}>4</option>
                                  <option value = {JSON.stringify({
                                      "i": i,
                                      "quantidade": "5"
                                  })}>5</option>
                                  <option value = {JSON.stringify({
                                      "i": i,
                                      "quantidade": "6"
                                  })}>6</option>
                                </select>
                               
                            </div>
                            
                          </TableCell>

                          <TableCell style={{width:'23%'}}> 
                            <div className="form-group row">
                              <input readOnly type="text" className="form-control col-6" id="" value={parseInt(quarto.pax) + parseInt(quarto.chd) + parseInt(quarto.chdFree)}/>
                              <label style={{width:'2%'}}></label>
                              <label style={{marginTop:'5%'}} >{" / " + quarto.QtdeMaxPAX + " "}</label>
                              <label style={{color:'#EB7228'}}>{(parseInt(quarto.pax) + parseInt(quarto.chd) + parseInt(quarto.chdFree)) > parseInt(quarto.QtdeMaxPAX) ? "Capacidade máxima exedida" : ""}</label>
                            </div>
                          </TableCell>
                          <TableCell style={{width:'7%'}}> 
                                <button style={{backgroundColor: "transparent", border: "none"}} onClick={this.removePosition.bind(this,i)}>
                                  <DeleteIcon/>
                                </button>
                          </TableCell>
                    </TableRow>
                  )
                }
              )
            }
        </TableBody>
        </Table>
      </div>
      <label>*Considerado como adulto pagante, criança de 13 anos acima.</label>
      <br></br>
      <br></br>
    <button type="submit" className="botaoCalc" onClick={this.handleSubmit}>Calcular</button>  

    <div>
    <Dialog
      open={this.state.open}
      onClose={() => this.state.close}
      fullWidth='true'
      maxWidth='lg'
      aria-labelledby="form-dialog-title"
    >
      <DialogTitle id="form-dialog-title">
      <h3>Resumo</h3>
      <div style={{padding:'1%'}}></div>
      <div className="form-group" >

      <div className="form-group row">
        <label style={{width:'2%'}}></label>
        <h8 style={{width:'14%'}}>Item</h8>
        <h8 style={{width:'14%'}}>Cod.</h8>
        <h8 style={{width:'14%'}}>Adultos</h8>
        <h8 style={{width:'14%'}}>Crianças</h8>
        <h8 style={{width:'14%'}}>Crianças Free</h8>
        <h8 style={{width:'14%'}}>Pontos</h8>
        <h8 style={{width:'14%'}}>Valor</h8>
      </div>
        <Table
          aria-label="a dense table" 
        >
          <TableBody>
          {
            this.state.tipoEspacos.map(
              (quarto, i) => {
                return(
                  <TableRow key={i}>
                    <TableCell style={{width:'14%', justifyContent: 'space-between'}} > {i+1}</TableCell>
                    <TableCell style={{width:'14%', justifyContent: 'space-between'}} >{quarto.codigo}</TableCell>
                    <TableCell style={{width:'14%', justifyContent: 'space-between'}} >{quarto.pax}</TableCell>
                    <TableCell style={{width:'14%', justifyContent: 'space-between'}} >{quarto.chd}</TableCell>
                    <TableCell style={{width:'14%', justifyContent: 'space-between'}} >{quarto.chdFree}</TableCell>
                    <TableCell style={{width:'14%', justifyContent: 'space-between'}} >{quarto.QtdePontos}</TableCell>
                    <TableCell style={{width:'14%', justifyContent: 'space-between'}} >{"R$ " + quarto.Valor}</TableCell>
                  </TableRow>
                )
              }
            )
          }
          </TableBody>
        </Table>
        </div>
        <div style={{padding:'1%'}}></div>
      <div className="row">
        <div className="form-group col-6">
          <h8 for="inputCity">Temporada</h8>
          <div style={{padding:'1%'}}></div>
          <input readOnly type="text" className="form-control" value={this.state.PeriodoTemporada} />        
        </div>
        <div className="form-group col-3">
          <h8 for="inputCity">Total de pontos</h8>
          <div style={{padding:'1%'}}></div>
          <input readOnly type="text" className="form-control" value={this.state.pontos} />        
        </div>
        <div className="form-group col-3">
          <h8 for="inputCity">Pontos em reais</h8>
          <div style={{padding:'1%'}}></div>
          <input readOnly type="text" className="form-control" value={this.state.Valor + " *"} />        
        </div>
        <div className="form-group col-3">
          <h8 for="inputCity">Data de entrada</h8>
          <div style={{padding:'1%'}}></div>
          <input readOnly type="text" className="form-control" value={moment(this.state.dataChegada).format('DD/MM/YYYY') } />       
        </div>
        <div className="form-group col-3">
          <h8 for="inputCity">Data de saída</h8>
          <div style={{padding:'1%'}}></div>
          <input readOnly type="text" className="form-control" value={moment(this.state.dataSaida).format('DD-MM-YYYY') }/>        
        </div>
      </div>
      <br></br>

      { this.state.alimentacao ? 
        <div>
          <h3>Alimentação</h3>
          <div style={{padding:'1%'}}></div>
          <div className="form-group row">
            <label style={{width:'2%'}}></label>
            <h8 style={{width:'32%'}}> Nome </h8>
            <h8 style={{width:'32%'}}> Preço Unitário </h8>
            <h8 style={{width:'32%'}}> Preço Total </h8>
          </div>

          <Table
              tableHeaderColor="info"
              aria-label="simple table" fullWidth
            >
              <TableBody>
                {
                  this.state.alimentacao.map(
                    ali =>(
                      <TableRow key={ali.Codigo}>
                      <TableCell style={{width:'33%', justifyContent: 'space-between'}} > {ali.Nome }</TableCell>
                  
                      <TableCell style={{width:'33%', justifyContent: 'space-between'}} >R$ {ali.PrecoUnitario }</TableCell>
                
                      <TableCell style={{width:'33%', justifyContent: 'space-between'}} >R$ {ali.PrecoTotal}</TableCell>
                </TableRow>
                    )
                  )
                }

              </TableBody>
            </Table>

            <div style={{padding:'1%'}}></div>
            <div className="row">
              <div className="form-group col-6">
                <h8 for="inputCity">Valor total da Alimentação</h8>
                <div style={{padding:'1%'}}></div>
                <input readOnly type="text" className="form-control" value={"R$ " + this.state.precoTotal + " **"} />        
              </div>
            </div>
            <div className="col">
              <label>* Valor indicativo da conversão dos pontos</label>
              <br></br>
              <label>** Valor pedente de pagamento para a efetivação da reserva</label>
            </div>
          </div> : null
          }
        <div className="sweet-loading">
          <ClipLoader
            css={override}
            size={150}
            color={"#123abc"}
            loading={this.state.loading}
          />
        </div>
      </DialogTitle>
      <DialogAction className="DialogCalc">
        <div className="botoes" row>
          <button className="botaoCalc " variant="contained" onClick={() => { this.setState({open : false,})}} >Voltar</button>

          <div style={{padding:'1%'}}></div>

          <button className="botaoCalc " variant="contained" onClick={() => {this.setState({loading:true}) 
            this.getBandeira();
              }} >Reservar</button>
        </div>
      </DialogAction>
      
    </Dialog>       
  </div>
  <div className="sweet-loading">
        <ClipLoader
          css={override}
          size={150}
          color={"#123abc"}
          loading={this.state.loading}
        />
      </div>
</div>
  );
        
}
}

const mapStateToProps = ({ login }) => {
  const { user } = login;

  return {
    user
  };
};

export default connect (mapStateToProps, {
  
}) (Calculadora);
