import React from "react";
import classNames from "classnames";

import { makeStyles } from "@material-ui/core/styles";

import styles from "assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

const useStyles = makeStyles(styles);

export default function HeaderLinks(props) {
  
  
  const classes = useStyles();
  const { rtlActive } = props;
  
  
  const wrapper = classNames({
    [classes.wrapperRTL]: rtlActive
  });
 
  return (
    <div className={wrapper}> </div>
  );
}


