import React, { Component } from "react";
import {api} from '../../services/api';
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import Divider from '@material-ui/core/Divider';

import {hist} from '../../index';

import {cpfMask} from '../mask/index';

import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';


import Dialog from '@material-ui/core/Dialog';
import DialogAction from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';

import CheckIcon from "@material-ui/icons/Check";
import ErrorIcon from "@material-ui/icons/Error";
import TimerIcon from '@material-ui/icons/Timer';

import { connect } from 'react-redux';
//import ClipLoader from "react-spinners/ClipLoader";
const override = css`
  display: block;
  align: center;
  border-color: orange;
  position: absolute;
  z-index: 99;
  top:50%;
  left:40%;
`;

var timer_obj;

const Snack = {
  message: '',
  color: '',
  icon: null
}

class perfil extends Component {
  constructor(props){
    super(props);
    console.log(props)
      this.state = {
        contratos:[],
        cpf:'',
        senha_atual:'',
        nova_senha:'',
        repete_senha:'',
        loading: false,
        open: false,
      }
     
    }
    
    Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }
    loadingSnackBar = (snack) =>  { //Função para carregar a snack bar 
    this.setState({
        snackBarColor: snack.color,
        snackBarMessage: snack.message,
        snackbarIcon: snack.icon,
        snackBarOpen: true
    });
    setTimeout(
        function () {
            this.setState({ snackBarOpen: false });
        }.bind(this),
        5500
    );
    }
    expandedControl = panel => (event, isExpanded) => {
      var t = (isExpanded ? panel : false);
      this.setState({expanded: t})
    };
componentWillUnmount()
    {
      if(this.state.loading == true)
      {
        this.setState({loading:false})
        clearTimeout(timer_obj);
      }
    }

    componentDidMount()
    {
      if((this.props.user === null) || (typeof(this.props.user) === 'undefined'))
      {
        hist.push("/home")
        // voltar para a tela inicial
      }
      else
      {
        this.setState({
          cpf: this.props.user.cpf,
          email: this.props.user.email,
          nome: this.props.user.nome
        })
    
        setTimeout(() => {
          this.getContrato()
        },100)
      }
    }

    getContrato = event =>  {
      this.setState({loading:true})

      timer_obj = setTimeout(() => {
        if(this.state.loading == true)
        {
          this.setState({loading:false})
          Snack.message ="Falha na comunicação com o servidor"
          Snack.color = "warning"
          Snack.icon = ErrorIcon
          this.loadingSnackBar(Snack)
        }
      }, 20000)

        api.get('/consultaContrato?cpf='+this.state.cpf.replace('.','').replace('.','').replace('-',''), { headers: { token: this.props.user.access_token, email: this.props.user.email}})
          .then(response => {
            this.setState({ 
              contratos: [].concat(response.data.Contrato),
            })
            this.setState({loading:false})
          })
          .catch(error => 
            {
              console.log("ERROR:",{error})
              Snack.message ={error}.error.response.data.message
              Snack.color = "warning"
              Snack.icon = ErrorIcon
              this.loadingSnackBar(Snack)
              //alert({error}.error.response.data.message);
              this.setState({loading:false})
              clearTimeout(timer_obj);
            })
    }

    alterarsenha = event =>{

      const senha = {
        nome: this.state.nome,
        sobrenome:this.state.sobrenome,
        senha_atual:this.state.senha_atual,
        nova_senha: this.state.nova_senha,
      }
        if(this.state.nova_senha !== this.state.repete_senha)
          {
              Snack.message ="As senha não são iguais. Confira e tente novamente"
              Snack.color = "warning"
              Snack.icon = ErrorIcon
              this.loadingSnackBar(Snack)
            //alert("As senha não são iguais. Confira e tente novamente")
            clearTimeout(timer_obj);
          }
        else
          {
            api.put('/hospede/pass', senha , { headers: { token: this.props.user.access_token, email: this.props.user.email}})
              .then(response => {

                this.setState({
                  open: false,
                  mensagem: response.data.message,
                })
                Snack.message = response.data.message
                Snack.color = "success"
                Snack.icon = CheckIcon
              })
          
          .catch(function (error) {
            Snack.message ={error}.error.response.data.message
            Snack.color = "warning"
            Snack.icon = ErrorIcon
            
            //alert(this.state.message);
          })
          this.loadingSnackBar(Snack)
          
         
        }
      }
      

    handleChange = event =>{
      switch(event.target.id)
      {
        case 'nome':
        this.setState(
          {
            nome : event.target.value
          }
        )
        break;
        case 'sobrenome':
        this.setState(
          {
            sobrenome : event.target.value
          }
        )
        break;
        case 'senha_atual':
        this.setState(
          {
            senha_atual : event.target.value
          }
        )
        break;
        case 'nova_senha':
        this.setState(
          {
            nova_senha : event.target.value
          }
        )
        break;
        case 'repete_senha':
        this.setState(
          {
            repete_senha : event.target.value
          }
        )
        break;
        default:
          break;
      }
    }
        
   
  render() {
    return (
      <div>
        <div className="sweet-loading">
          <ClipLoader
            css={override}
            size={150}
            //size={"150px"} this also works
            color={"#123abc"}
            loading={this.state.loading}
          />
        </div> 
        <Snackbar
          place="bc"
          open={this.state.snackBarOpen}
          closeNotification={() => this.setState({ snackBarOpen: false })}
          close
          >
          <MuiAlert elevation={6} variant="filled" severity={this.state.snackBarColor}>
            {this.state.snackBarMessage}
          </MuiAlert>
        </Snackbar>  
        <h3>Perfil do Login:</h3>
        <div className="form-row">
          <div className="form-group col-md-8 ">
            <label>Nome</label> 
            <input readOnly type="text" className="form-control " value={this.state.nome}></input>
            <div style={{padding:10}}></div>
          </div>
            <div className="form-group col-md-8 "> 
              <label>Email</label> 
              <input readOnly type="text" className="form-control " value={this.state.email}></input>
              <div style={{padding:10}}></div>
            </div>
            <div className="form-group col-md-8 "> 
              <label>CPF</label> 
              <input readOnly type="text" className="form-control " value={cpfMask(this.state.cpf)}></input>
              <div style={{padding:10}}></div>
            </div>
        </div>
        <form style={{padding:10}} >
          <label>*Para alterações entre no APP Tauá</label>
          <br></br>
          <Divider variant="middle" />
          <br></br>
          <h3>Perfis do contrato:</h3>
            {
              this.state.contratos.map(
                (contrato,i )=>(               
                  <div key={contrato.ID}>
                    <ExpansionPanel id={i.toString()} expanded={this.state.expanded === i.toString()} onChange={
                        this.expandedControl(i.toString())
                      }>
                      <ExpansionPanelSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Perfil do contrato {contrato.ID}</Typography>
                      </ExpansionPanelSummary>
                      <ExpansionPanelDetails>
                        <Typography>
                          <h6>Perfil {i+1}</h6>
                          { <form style={{padding:10}} >
                          {
                            [].concat(contrato.Profiles.Profile).map(
                              profile =>(
                                <div>
                                  <div className="form-group col-md-8">
                                    <label htmlFor="inputEmail4">Nome</label>
                                    <input readOnly type="text" className="form-control" id="inputNome"  value={profile.Customer.PersonName.GivenName} />
                                  </div>
                                  <div className="form-group col-md-8">
                                    <label htmlFor="inputEmail4">Sobrenome</label>
                                    <input readOnly type="text" className="form-control" id="inputNome" value={profile.Customer.PersonName.Surname } />
                                  </div>
                  
                                  <div className="form-group col-md-8">
                                    <label htmlFor="inputAddress">Email</label>
                                    <input readOnly type="text" className="form-control" id="inputEmail"  value={profile.Customer.Email}/>
                                  </div>
                                  <label>*Para alterações, entre em contato com a central do TVC pelo telefone (31) 3236 1920</label>
                                  <br></br>
                                  <br></br>
                                </div>
                              )
                            )
                          }
                          </form> 
                          }
                        </Typography>
                      </ExpansionPanelDetails>
                    </ExpansionPanel>
                      </div>
                )
              )
            }
            </form>
        
        <button className="botaoCalc " variant="contained" onClick={() => { this.setState({open : true,})}}
                    >Alterar senha</button>
        <Dialog
          open={this.state.open}
          onClose={() => this.state.close}
          fullWidth='true'
          maxWidth='md'
          aria-labelledby="form-dialog-title"
            >
              <DialogTitle id="form-dialog-title">       
                <div className="form-group col">
                  <h3>Atualize suas informações</h3>
                  <div className="form-group col">
                    <h8>Nome</h8> 
                    <div style={{padding:'1%'}}></div>
                    <input type="text" className="form-control" id="nome" value={this.state.nome} onChange={this.handleChange}></input>
                    <div style={{padding:'1%'}}></div>
                  </div>
                  <h3>Alterar senha</h3>
                  <div className="form-group col">
                    <h8>Senha Atual</h8> 
                    <div style={{padding:'1%'}}></div>
                    <input type="password" className="form-control" id="senha_atual" onChange={this.handleChange}></input>
                    <div style={{padding:'1%'}}></div>
                  </div>
                    <div className="form-group col"> 
                      <h8>Nova senha</h8> 
                      <div style={{padding:'1%'}}></div>
                      <input type="password" className="form-control" id="nova_senha" onChange={this.handleChange}></input>
                      <div style={{padding:'1%'}}></div>
                    </div>
                    <div className="form-group col"> 
                      <h8>Repita a nova senha</h8> 
                      <div style={{padding:'1%'}}></div>
                      <input type="password" className="form-control" id="repete_senha" onChange={this.handleChange}></input>
                      <div style={{padding:'1%'}}></div>
                    </div>
                    <label style={{color:'#EB7228'}}>{this.state.repete_senha !== this.state.nova_senha ? "As senhas não são iguais" : ""}</label>
                  </div>
                </DialogTitle>
              <DialogAction className="DialogCalc">
                <div className="botoes" row>
                  <button className="botaoCalc " variant="contained" onClick={() => { this.setState({open : false,})}} >Voltar</button>
                  <div style={{padding:10}}></div>
                  <button className="botaoCalc " variant="contained" onClick={this.alterarsenha}> Concluir </button>
                </div>
              </DialogAction>
          </Dialog>
          
      </div>      

    );
  }
}
const mapStateToProps = ({ login }) => {
  const { user } = login;

  return {
    user
  };
};

export default connect (mapStateToProps, {
  
}) (perfil);
